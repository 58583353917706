<template>
<div>
  <div class="w-50"  v-if="event.welcome.logo">
    <img class="w-100" :src="event.welcome.logo" v-if="event.welcome.logo"/>
  </div>
  <video playsinline autoplay muted loop width="50%" v-if="event.welcome.video">
    <source :src="event.welcome.video" type="video/mp4">
  </video>
  <div class="text mt-3" v-for="(m, index) in event.welcomeMessages" :key="index">
    {{ inject(m, $app) }}
  </div>
  <!-- Preview for video -->
  <div class="mt-2 mb-2" v-if="event.callOptions && event.callOptions.video">
    <button class="btn btn-primary" v-b-modal.test-preview-gum-modal>Authorize Camera and Test</button>
    <b-modal id="test-preview-gum-modal" centered ok-only size="lg" title="Video Setup">
      <preview-gum/>
    </b-modal>
  </div>
  <progress/>
  <!-- User list -->
  <event-user-list
    v-if="event.workflowOptions.welcomeShowsUsers"
    class="mt-3"
    :eventUser="eventUser"
    :eventUsers="eventUsers"/>
</div>
</template>

<script>
import { inject } from "@/services/utils";
import eventUserList from '../components/eventUserList.vue';
import { db } from "@/services/db";
import PreviewGum from '../components/previewGum.vue';

export default {
  components: { eventUserList, PreviewGum },
  props: {
    event: Object,
    eventUser: Object,
  },
  data() {
    return {
      showPreviewGum: false,
      eventUsers: null,
    };
  },
  mounted() {
    this.$bind("eventUsers", db.collection(`LiveEvents/${this.event.id}/users`));
  },
  methods: {
    inject,
  }
}
</script>

<style scoped>

.text {
  white-space: pre-line;
}

</style>