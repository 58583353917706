<template>
<div class="text-center">
  <h1>Thanks for participating to this event!</h1>
  <div v-if="event.workflowOptions.showNextEvents">
    <p>If you aren't registered to upcoming events yet, see available events:</p>
      <button
        class="btn btn-primary"
        @click="$router.push({ path: '/guest/events/list', query: $route.query })"
      >Register to an upcoming event</button>
  </div>
  <div v-else>
    Keep an eye on your mailbox for next event.
  </div>
  <br />❤️
  <br />The {{ $app.productName }} Team
  <br />
  <a :href="`mailto:${$app.contactEmail}`">{{ $app.contactEmail }}</a>
</div>
</template>

<script>
export default {
  props: {
    event: Object
  }
}
</script>

<style>

</style>