<template>
<div>
  <!-- Composer View -->
  <div class="w-100">
    <composer
      ref="composer"
      :template="template"
      :layout-config="layoutConfig"
      :users="composerUsers"
      :config="{debug:false, muted:!useComposerAudio}"
      @stream-composed="(s) => { final = s; }"
    />
  </div>
  <!-- WebRTC : Hidden -->
  <component
    :is="$app.useRtcComponent"
    v-if="room"
    ref="webrtc"
    :user="eventUser"
    :room="room"
    :roomUsers="room.users"
    @usersChange="webRTCUsersChanged"
    :userConfig="{'settings':deviceSettings}"
  />
  <!-- Screening Room -->
  <video-layout 
    class="border-bottom"
    :config="{numColumns:4,volumeMuted:true, showNote:true}" 
    :users="videoLayoutUsers"/>
  <audio-out
    v-if="!useComposerAudio"
    :users="users"/>
  <!-- Composer Controls -->
  <div class="container">
  <div class="pb-2 form-inline sub-mt-2 sub-mr-2 border-bottom">
    <div>Layouts:</div>
    <template v-for="(lc, i) in layoutCombos">
      <button class="btn" 
        :class="{'btn-primary':!layoutConfig || layoutConfig.label != lc.label,
         'btn-info':layoutConfig && layoutConfig.label == lc.label}"
        v-shortkey="[lc.shortKey]" 
        @shortkey="layoutConfig = lc"
        @click="layoutConfig = lc" :key="'lc_' + i">
          {{ lc.label }}
          <b-badge v-if="lc.shortKey">{{ lc.shortKey.toUpperCase() }}</b-badge>
        </button>
    </template>
  </div>
  <!-- Tabs controls -->
  <b-tabs class="mt-2">
    <b-tab>
      <template #title>
        <b-spinner ref="liveIndic" variant="danger" type="grow" small v-if="event.state == 'running'"/>
        <span v-else>⏸</span>
        Live Show
      </template>
      <!-- Event Config -->
      <div v-if="showEventConfig" class="pb-2 border-bottom">
        <div class="form-inline sub-mt-2 sub-mr-2 alert-info">
          <div>State:</div>
          <b-form-select class="mr-2" :value="event.state" :options="eventStateOptions" 
            @change="(v) => updateEvent(event, {state:v})"/>
        </div>
        <div class="form-inline sub-mt-2 sub-mr-2">
          <div>Template:</div>
          <b-form-select class="mr-2" v-model="eventComposerTemplate" :options="templateOptions"/>
          <b-form-select class="mr-2" v-model="eventComposerScaleFactor" :options="scaleFactorOptions"/>
          <button class="btn btn-primary" @click="saveTemplateConfig">Save</button>
          <button class="btn btn-primary" @click="showEventConfig = false">Close</button>
        </div>
      </div>
      <!-- Event workflow -->
      <div class="form-inline mb-2 sub-mt-2 sub-mr-2">
        <button class="btn btn-secondary" @click="showEventConfig = !showEventConfig; loadTemplateConfig()">
          Event Config
        </button>
        <button class="btn btn-primary" @click="stop()" v-if="event.state == 'running'">Pause</button>
        <button class="btn btn-primary" @click="start()" v-else>
          {{ eventStateRunningOrPaused ? 'Continue' : 'Start' }}
        </button>
        <button class="btn btn-outline-danger" @click="terminateShow()">End</button>
      </div>
      <!-- Broadcasting -->
      <div>Broadcasting: {{ broadcastingOn ? "On" : "Off" }}</div>
      <broadcast
        ref="broadcast"
        :channel="event.id"
        :stream="!broadcastingDisabled && final"
        @broadcasting-change="(v) => broadcastingOn = v"/>
      <!-- StreamingEditor -->
      <div>Recording: {{ streamingDisabled ? "Disabled" : $refs.streaming && $refs.streaming.recording ? "On" : "Off" }}</div>
      <debug-obj v-if="$debug.isOn" label="recording" :objData='recording'/>
      <stream-recorder v-if="event && recording"
        ref="streaming"
        :stream="final"
        :config="{disableEdit:!showStreamingOptions, hideControls:true, wsIp:$app.streamingServerIp, hlsBucket:hlsBucket}"
        @recording-change="recordingChange"
        />
      <div class="form-inline sub-mt-2 sub-mr-2">
        <span v-if="$debug.isOn">
          <button class="btn btn-primary mr-2" @click="showStreamingOptions = !showStreamingOptions">Options</button>
          <button class="btn btn-primary mr-2" @click="createNewRecording" :disabled="!recording || isRecording">Start New Recording</button>
        </span>
        <button class="btn btn-secondary mr-2" @click="$bvModal.show('modal-preview');" :disabled="isRecording">See Recordings</button>
      </div>
      <!-- Preview Modal -->
      <b-modal id="modal-preview" centered title="Previews">
        <recordings-list :event="event"/>
      </b-modal>
    </b-tab>
    <!-- Tab: Event Users: Moderators and Guests -->
    <b-tab title="Users" active>
      <!-- Speaker property room -->
      <event-user-list
        class="mt-2"
        :eventUser="eventUser"
        :eventUsers="userListUsers"
        :config="{showSelect:true, showState:true, showBinding:true}"
        @selected-user="onSelectUser"/>
      <!-- Text Editor -->
      <div v-if="guestEventUser">
        <div class="alert-info" v-if="$debug.isOn">{{ guestEventUser.id }}</div>
        <b-input class="mt-1" placeholder="Name" v-model="guestName" @update="guestEdited=true" @keypress="updateSlotInfoOnEnter"/>
        <b-input class="mt-1" placeholder="Bio" v-model="guestBio" @update="guestEdited=true" @keypress="updateSlotInfoOnEnter"/>
        <button class="mt-1 btn btn-primary" v-if="guestEdited" @click="updateSlotInfo()">Update</button>
        <div class="form-inline sub-mt-2 sub-mr-2"> 
          <template v-for="(c, i) in userSlots">
            <button class="btn btn-primary btn-sm" @click="send(i)" v-if="slots[i] != guestEventUser.id" :key="'send_' + i">Send to {{c}}</button>
            <button class="btn btn-outline-warning btn-sm" @click="remove(i)" v-else :key="'rem_' + i">Remove from {{c}}</button>
          </template>
          <button
            :disabled="guestEventUser.role == 'moderator'"
            class="btn btn-primary btn-sm" 
            @click="sendToAudience">Send to Audience</button>
        </div>
      </div>
    </b-tab>
    <!-- Tab: Audio -->
    <b-tab title="Audio" class="pt-3">
      <div v-for="(m, i) in nonNullAudioMixers" :key="'am_'+i" class="d-flex flex-row">
        <label class="w-25" :for="'am_'+i">{{ m.name }}</label>
        <b-form-input :id="'am_'+i" v-model="audioMixers[m.userIndex].gain" type="range" min="0" step="0.1" max="2" @input="(v) => updateGain(m.stream, v)" />
        <div class="pl-2 pr-2" style="width:20px">{{audioMixers[m.userIndex].gain}}</div>
      </div>
    </b-tab>
    <!-- Tab: Content -->
    <b-tab title="Content" class="pt-2">
      <!-- Room Controls -->
      <div class="form-inline sub-mr-2" v-if="$refs.webrtc">
        <!-- Mute -->
        <button class="btn btn-primary" @click="$refs.webrtc.setMuted(false)" v-if="$refs.webrtc.muted">Unmute Me</button>
        <button class="btn btn-primary" @click="$refs.webrtc.setMuted(true)" v-else>Mute Me</button>
        <!-- Settings Toggle -->
        <button class="btn btn-primary" @click="showSettings = !showSettings">Capture Settings</button>
        <!-- Share Screen -->
        <button class="btn btn-primary" @click="$refs.webrtc.shareScreen()" v-if="!$refs.webrtc.screenStream" >Share Screen</button>
        <button class="btn btn-primary" @click="$refs.webrtc.stopScreenShare()" v-else>Stop Screen Sharing</button>
        <!-- Content Options -->
        <button class="btn btn-primary" @click="showContentOptions = !showContentOptions">Content Options</button>
        <!-- Slate Editor -->
        <button class="btn btn-primary" @click="showSlateEditor = !showSlateEditor">Slate Editor</button>
        <!-- Q&A Module -->
        <b-button class="mr-2 mt-2" v-b-toggle.sidebar-questions>Show Questions</b-button>   
      </div>
      <!-- Content Options -->
      <div v-if="showContentOptions"
        class="border-top mt-2 pt-2">
        <div class="font-weight-bold">Content Options</div>
        <b-form-checkbox class="mt-2" v-model="disableMirrorAllOnUsers">Disable Mirror on All Users</b-form-checkbox>
        <button class="btn btn-primary mt-2" @click="reSendUsers()">Save</button>
      </div>
      <!-- Screen Share Settings -->
      <div v-if="$refs.webrtc && $refs.webrtc.screenStream"
        class="border-top mt-2 pt-2">
        <div class="font-weight-bold">Screen Share Options</div>
        <div class="form-inline sub-mr-2 sub-mt-2">
          <div>Fit:</div>
          <b-form-select v-model="screenStreamFit" :options="screenStreamFitOptions"/>
          <div>Background Color:</div><input v-model="screenStreamBgColor" type="color"/>
          <button class="btn btn-primary" @click="reSendUsers()">Save</button>
        </div>
      </div>
      <!-- Capture Settings -->
      <div v-if="showSettings" class="border-top mt-2 pt-2">
        <div class="font-weight-bold">Capture Settings</div>
        <webrtc-settings 
          :current="deviceSettings"
          @selection-saved="saveDeviceSettings"
          @close="showSettings = false;"/>
      </div>
      <!-- Slate Editor -->
      <div v-if="showSlateEditor" class="border-top mt-2 pt-2">
        <div class="font-weight-bold">Slate Editor</div>        
        <b-input class="mt-2" placeholder="Title" v-model="slateTitle" @keypress="sendSlateOnEnter"/>
        <b-input placeholder="SubTitle" v-model="slateSubTitle" @keypress="sendSlateOnEnter"/>
        <div class="form-inline mt-1 mb-1">
          <button class="btn btn-primary btn-sm mr-2" @click="sendSlate()">Send</button>
        </div>
      </div>
      <!-- Debug -->
      <div v-if="$debug.isOn" class="w-100 alert-info mt-2 mb-2">
        <button class="btn btn-primary mr-2" @click="useComposerAudio = false" v-if="useComposerAudio">Audio From Audio Out</button>
        <button class="btn btn-primary mr-2" @click="useComposerAudio = true" v-else>Audio From Composer</button>
      </div>
    </b-tab>
    <!-- Tab: Audience -->
    <b-tab title="Audience">
      <div class="form-inline mt-3 sub-mr-2">
        <button class="btn btn-primary" @click="eventSetMerge(event, {workflowOptions:{enableChat:false}})" v-if="event.workflowOptions.enableChat">Disable Chat</button>
        <button class="btn btn-primary" @click="eventSetMerge(event, {workflowOptions:{enableChat:true}})" v-else>Enable Chat 💬</button>
        <button class="btn btn-primary" @click="eventSetMerge(event, {workflowOptions:{enableQnA:false}})" v-if="event.workflowOptions.enableQnA">Disable Q&amp;A</button>
        <button class="btn btn-primary" @click="eventSetMerge(event, {workflowOptions:{enableQnA:true}})" v-else>Enable Q&amp;A</button>
        <button class="btn btn-primary" @click="eventSetMerge(event, {workflowOptions:{enableReact:false}})" v-if="event.workflowOptions.enableReact">Disable React</button>
        <button class="btn btn-primary" @click="eventSetMerge(event, {workflowOptions:{enableReact:true}})" v-else>Enable React 😹</button>
        <button class="btn btn-primary" @click="setAudienceRooms(false)" v-if="event.workflowOptions.audienceMultiRoomOptions">Disable Audience Rooms</button>
        <button class="btn btn-primary" @click="setAudienceRooms(true)" v-else>Enable Audience Rooms</button>
      </div>
    </b-tab>
  </b-tabs>
  </div>
  <!-- Q&A Module Sidebar-->
  <b-sidebar id="sidebar-questions" right sidebar-class="border-left border-info" shadow 
    title="Questions"
    @change="(v) => { $app.router.showRightSideBar = v; }">
    <questions-list class="ml-2 mt-2"
      :event="event"
      :config="{showActionsPlay:true}"
      :eventUsers="[...eventUsers, ...eventUsersAudience]"
      @send="sendAsset"
      @play="playAsset"
    />
  </b-sidebar>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("producerv", true);
import { db, serverTimestamp } from '@/services/db';
import Composer from '@/components/composer.vue';
import WebRTC2 from "@/components/webrtc2";
import roomRTC from "@/components/roomRTC";
import EventUserList from '@/components/eventUserList.vue';
import DebugObj from '@/components/debugObj.vue';
import VideoLayout from '@/components/videoLayout.vue';
import { updateEventUser, updateEvent, eventSetMerge } from '@/services/event-manager';
import { getSavedDeviceSettings, saveDeviceSettings } from '@/services/mediautils';
import AudioOut from '@/components/audioOut.vue';
import { bindInheritedObj, createOrOverride } from '@/services/dbutils';
import RecordingsList from '@/components/recordingsList.vue';
import Broadcast from '@/components/agoraRTC.vue';
import { arraysEqual } from '../services/utils';

export default {
  components: {
    Composer,
    StreamRecorder: () => import('@/components/streamRecorder.vue'),
    roomRTC,
    peerRTC : WebRTC2,
    EventUserList,
    DebugObj,
    VideoLayout,
    AudioOut,
    WebrtcSettings: () => import('@/components/webrtcSettings.vue'),
    RecordingsList,
    Broadcast,
    QuestionsList: () => import('@/components/questionsList.vue'),
  },
  props: {
    event: Object,
    eventUser: Object,
    eventUsers: Array,
    eventUsersAudience: Array,
  },
  data() {
    return {
      room: null,
      template: null,
      template_watcher: null,
      layoutConfig: null,
      composerUsers: [],
      users: [],
      broadcastingOn: false,
      broadcastingDisabled: false,
      streamingDisabled: false,
      isRecording: false,
      final: null,

      shortKeyMapping: {
        'full A': '1',
        'full B': '2',
        'full C': '3',
        'full D': '4',
        'split AB': 'f1',
        'split ABC': 'f2',
        'split ABCD': 'f3',
      },
      userSlots: ['A','B','C','D'],
      userListUsers: [],
      videoLayoutUsers: [],
      layoutCombos: [],

      useComposerAudio: false,
      showEventConfig: false,
      eventStateOptions: [
        null, 'starting', 'running', 'paused', 'complete'
      ],

      deviceSettings: null,
      showSettings: false,
      showStreamingOptions: false,
      showSlateEditor: false,

      eventComposerTemplate: null,
      eventComposerScaleFactor: null,
      templates: [],
      scaleFactorOptions: [
        {text: "480p", value:1},
        {text: "720p", value:1.5},
        {text: "1080p", value:2.25},
      ],

      recordingsCollection: null,
      recordings: [],
      recordingsLimit: 3,
      recording: null,

      slots: [],
      content: {},
      audioMixers: [],

      guestEventUser: null,
      guestName: "",
      guestBio: "",
      guestEdited: false,

      slateTitle: "",
      slateSubTitle: "",

      screenStreamFit: 'cover',
      screenStreamFitOptions: [
        'cover',
        'contain',
        'fill',
      ],
      screenStreamBgColor: '#ffffff',

      showContentOptions: false,
      disableMirrorAllOnUsers: false,
    }
  },
  computed: {
    hlsBucket() {
      if (this.event && this.recording)
        return `${this.event.id}/${this.recording.id}`;
      else
        return '';
    },
    layoutOptions() {
      if (!this.template)
        return [];
      return Object.keys(this.template.layouts).sort((a, b) => a.localeCompare(b));
    },
    nonNullAudioMixers() {
      return this.audioMixers.filter((m) => m != null);
    },
    templateOptions() {
      return this.templates.map((t) => t.id);
    },
    // EventState
    eventStateRunningOrPaused() {
      return this.event.state == 'running' || this.event.state == 'paused';
    },
  },
  watch: {
    eventUsers() {
      this.updateUsersListUsers();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    updateEvent,
    // Template manipulation    
    scaleTemplate(template, factor) {
      template.dest.width *= factor;
      template.dest.height *= factor;
      // scale layers
      let scaleLayer = (layer) => {
        if (layer.x) layer.x *= factor;
        if (layer.y) layer.y *= factor;
        if (layer.width) layer.width *= factor;
        if (layer.height) layer.height *= factor;
        //log.log("layer", layer);
        return layer;
      };
      if (template.version == 2) {
        let layoutsScaled = [];
        template.layouts = Object.fromEntries(Object.entries(template.layouts).map(([key, superLayout]) => {
          if (superLayout.layers) {
            superLayout.layers = superLayout.layers.map((l) => scaleLayer(l));
            layoutsScaled.push(key);
          }
          else {
            Object.fromEntries(Object.entries(superLayout).map(([k, layout]) => {
              if (layout.layers) {
                layout.layers = layout.layers.map((l) => scaleLayer(l));
                layoutsScaled.push(k);
              }
              return [k, layout];
            }));
          }
          return [key, superLayout];
        }));
        //log.log("scaleTemplate scaled:", layoutsScaled);
      }
      else {
        template.layouts = Object.fromEntries(Object.entries(template.layouts).map(([key, layout]) => {
          log.log("map", [key, layout, layout.layers]);
          layout.layers = layout.layers.map((l) => scaleLayer(l));
          return [key, layout];
        }));
      }
      return template;
    },
    async loadTemplateOptions() {
      await this.$bind("templates", db.collection("LiveStudioTemplates").where("display", "==", true));
    },
    async loadTemplateConfig() {
      this.eventComposerTemplate = this.event.composerTemplate;
      this.eventComposerScaleFactor = this.event.composerScaleFactor;
    },
    async saveTemplateConfig() {
      await updateEvent(this.event, {
        composerTemplate:this.eventComposerTemplate, 
        composerScaleFactor:this.eventComposerScaleFactor,
      });
      this.loadTemplate();
    },
    // Init sequence
    async init() {
      this.initCapture();
      this.initRecordings();
      this.loadTemplateOptions();
      this.loadTemplate();
      await this.$bind("room", db.collection("LiveRooms").doc(this.event.roomId));
    },
    // Capture
    async initCapture() {
      this.deviceSettings = getSavedDeviceSettings();
    },
    saveDeviceSettings(s) {
      this.deviceSettings = s;
      saveDeviceSettings(s);
      this.$refs.webrtc.createUserStream(s);
      this.showSettings = false;
    },
    // Template
    async loadTemplate() {
      if (!this.event.composerTemplate) {
        log.error("No template selected");
        return;
      }
      log.log(`Load Template: ${this.event.composerTemplate}, Scale Factor: ${this.event.composerScaleFactor}`);
      if (this.template_watcher)
        this.template_watcher();
      this.template_watcher = await bindInheritedObj(this, "template", db.collection("LiveStudioTemplates"), this.event.composerTemplate, (template) => {
        return this.event.composerScaleFactor ? this.scaleTemplate(template, this.event.composerScaleFactor) : template;
      });
      this.updateLayoutCombos();
      this.setDefaultUser();
    },
    // Recording
    async initRecordings() {
      this.isRecording = this.event.state == 'running';
      this.recordingsCollection = db.collection(`LiveEvents/${this.event.id}/recordings`);
      await this.$bind("recordings", this.recordingsCollection.orderBy("index", "desc"));
      if (!this.recordings.length) {
        log.log('create new recording');
        let rec = await this.recordingsCollection.add({index:0});
        await this.$bind("recording", this.recordingsCollection.doc(rec.id));
      } else {
        this.recording = this.recordings[0];
        log.log("recording", this.recordings.length, this.recording, this.event.recordingId);
        if (this.recording)
          updateEvent(this.event, {recordingId:this.recording.id});
      }
    },
    async createNewRecording() {
      if (!this.recording)
        return;
      this.recordingsCollection.doc(this.recording.id).update({endTime:serverTimestamp()});
      let rec = await this.recordingsCollection.add({index:this.recordings.length});
      log.log("new recording", rec.id);
      updateEvent(this.event, {recordingId:rec.id});
      try {
        this.$bind("recording", this.recordingsCollection.doc(rec.id));
      } catch (e) {
        log.log("createNewRecording Error", e);
      }
    },
    async terminateShow() {
      if (await this.$bvModal.msgBoxConfirm(`Are sure you want to end the event?`, {centered: true})) {
        log.log("terminateShow confirmed");
        await updateEvent(this.event, {state:'complete'});
      }
    },
    async webRTCUsersChanged(users) {
      log.log("webRTCUsersChanged", users);
      this.users = users;
      this.updateMixer();
      this.updateLayoutCombos();
      this.reSendUsers();
    },
    updateUsersListUsers() {
      let res = [];
      for (let [i, user] of this.eventUsers.entries()) {
        let index = this.slots.findIndex((s) => s == user.id);
        //log.log("updateUsersListUsers slots.findIndex", index);
        res[i] = Object.assign({binding:this.userSlots[index], id:user.id}, user);
      }
      log.log("updateUsersListUsers", res);
      this.userListUsers = res;
    },
    updateVideoLayoutUsers() {
      let res = [];
      for (let [i, user] of this.users.entries()) {
        let index = this.slots.findIndex((s) => s == user.id);
        //log.log("updateVideoLayoutUsers slots.findIndex", index);
        let item = {note:'No Channel', id:user.id};
        if (index >= 0)
          item.note = "Channel: " + this.userSlots[index];
        res[i] = Object.assign(item, user);
      }
      log.log("updateVideoLayoutUsers", res);
      this.videoLayoutUsers = res;
    },
    getChannelScreens() {
      let res = [];
      for (let user of this.users) {
        if (user.screenStream) {
          let index = this.slots.findIndex((s) => s == user.id);
          if (index >= 0)
            res.push(this.userSlots[index]);
        }
      }
      return res;
    },
    updateLayoutCombos() {
      if (!this.template) {
        log.log("no template");
        return;
      }
      let res = [];
      let channelsScreens = this.getChannelScreens();
      let slotEntries = Array.from(this.slots.entries()).filter(([, value]) => value != null);
      log.log("updateLayoutCombos, slots=", slotEntries, "channelsScreens=", channelsScreens);
      for (let [layoutName, layout] of Object.entries(this.template.layouts)) {
        if (!layout.capabilities) {
          res.push({label:layoutName, id:layoutName});
          continue;
        }
        // Check if applies
        let apply = slotEntries.length >= (layout.capabilities.minUserStreams || 0);
        apply &= channelsScreens.length >= (layout.capabilities.minScreenStreams || 0);
        if (!apply) {
          log.log("updateLayoutCombos skip", layoutName, "caps=", layout.capabilities);
          continue;
        }
        // Compute combinations
        for (let i = 0; i < this.slots.length; ++i) {
          // log.log(`${layoutName} starter ${this.userSlots[i]}`);
          if (!this.slots[i])
            continue;
          let prevUsers = null;
          for (let len = 0; len < Math.min(layout.capabilities.maxUserStreams, this.slots.length) + 1; ++len) {
            let users = [];
            if (this.slots[i])
              users.push(this.userSlots[i]);
            for (let c = 0; c < len; ++c) {
              if (i != c && this.slots[c])
                users.push(this.userSlots[c]);
            }
            // log.log(`${layoutName} ${users}`);
            if (prevUsers && arraysEqual(prevUsers, users))
              continue;
            if (users.length < layout.capabilities.minUserStreams
              || users.length > layout.capabilities.maxUserStreams)
              continue;
            prevUsers = users;
            
            let channelsVariation = users.join("");
            let label = `${layoutName} ${channelsVariation}`;
            let layoutConfiguration = {
              id:layoutName,
              variant:`${layoutName}-${users.length}`,
              users,
              label,
              shortKey:this.shortKeyMapping[label],
            };
            res.push(layoutConfiguration);
          }
        }
      }
      log.log("updateLayoutCombos", res);
      this.layoutCombos = res;
    },
    // Guests
    onSelectUser(eu) {
      log.log("onSelectUser", eu);
      this.guestEventUser = eu;
      if (this.guestEventUser) {
        this.guestName = this.guestEventUser.name;
        this.guestBio = this.guestEventUser.shortBio;
      }
    },
    async setDefaultUser() {
      log.log("setDefaultUser");
      this.updateLayoutCombos();
      if (this.users[0] && !this.slots[0]) {
        this.onSelectUser(this.eventUser);
        await this.send(0);
        if (this.event.state != 'running')
          this.layoutConfig = this.layoutCombos.find((lc) => lc.label == 'full A');
        else 
          this.layoutConfig = this.layoutCombos.find((lc) => lc.label == this.template.init.layout);
      }
    },
    async reSendUsers() {
      log.log("reSendUsers eventUsers=", this.eventUsers, "slots=", this.slots);
      let cus = [];
      for (let [index, id] of this.slots.entries()) {
        if (!id)
          continue;
        let cu = this.eventUsers.find((eu) => eu.id == id);
        if (cu) {
          let rtcUser = this.users.find((u) => u.id == id) || {};
          cus[index] = Object.assign({
            id:rtcUser.id,
            stream: {
              stream: rtcUser.stream,
              mirror: !this.disableMirrorAllOnUsers,
            }, 
            screen: {
              stream: rtcUser.screenStream,
              fit: this.screenStreamFit,
              backgroundColor: this.screenStreamBgColor,
            },
          }, cu);
        } else if (this.content[id]) {
          let content = this.content[id];
          cus[index] = Object.assign({
            id: content.eventUser.id,
            stream: {
              id: content.eventUser.id,
              media: content.asset,
              mirror: true,
            }
          }, content.eventUser);
        }
      }
      this.composerUsers = cus;
      log.log("reSendUsers composerUsers=", this.composerUsers);
      this.updateUsersListUsers();
      this.updateVideoLayoutUsers();
      this.updateLayoutCombos();
    },
    async send(slot) {
      let userIndex = this.room.users.findIndex((e) => e == this.guestEventUser.id);
      log.log("sendSlot userIndex=", userIndex);
      if (userIndex < 0) {
        log.error("User not in the room");
        return;
      }
      this.$set(this.slots, slot, this.guestEventUser.id);
      this.updateSlotInfo();
      this.reSendUsers();
    },
    async sendAsset(index, eventUser, asset) {
      log.log("sendAsset", index, eventUser, asset);
      this.$set(this.slots, index, eventUser.id);
      this.content[eventUser.id] = {
        eventUser,
        asset
      };
      this.reSendUsers();
    },
    async playAsset(index, eventUser, asset) {
      log.log("playAsset", index, eventUser, asset);
      await this.sendAsset(index, eventUser, asset);
      this.layoutConfig = this.layoutCombos.find((lc) => lc.label == 'full B');
      if (!this.layoutConfig) {
        log.error("notfound layout config full B");
        return;
      }
      asset.currentTime = 0;
      asset.onended = () => {
        log.log("asset.onended");
        asset.onended = null;
        this.layoutConfig = this.layoutCombos.find((lc) => lc.label == 'full A');
        this.remove(index);
        this.reSendUsers();
      };
      await asset.play();
    },
    // Slate
    async updateSlotInfoOnEnter(event) {
      if (event.keyCode == 13 || event.which == 13) {
        event.preventDefault();
        this.updateSlotInfo();
      }
    },
    async updateSlotInfo() {
      let user = {
        id: this.guestEventUser.id,
        name: this.guestName || "name",
        shortBio: this.guestBio || "",
      };
      await this.$refs.composer.updateUserAsset(user);
      updateEventUser(this.event.id, this.guestEventUser.id, user);
      this.guestEdited = false;
    },
    async remove(slot) {
      delete this.slots[slot];
      this.reSendUsers();
    },
    sendToAudience() {
      updateEventUser(this.event.id, this.guestEventUser.id, {role:null, state:null});
      this.guestEventUser = null;
    },
    async sendSlateOnEnter(event) {
      if (event.keyCode == 13 || event.which == 13) {
        event.preventDefault();
        this.sendSlate();
      }
    },
    async sendSlate() {
      await this.updateSlateAsset();
      this.reSendUsers();
    },
    async updateSlateAsset() {
      let slate = {
        title: this.slateTitle || "slate",
        subTitle: this.slateSubTitle || "",
      };
      await this.$refs.composer.updateSlateAsset(slate);
    },
    start() {
      if (this.streamingDisabled) {
        updateEvent(this.event, {state:'running'});
      }
      else
        this.$refs.streaming.start();
    },
    stop() {
      if (this.streamingDisabled) {
        updateEvent(this.event, {state:'paused'});
      }
      else
        this.$refs.streaming.stop();
    },
    recordingChange(isRecording, closing) {
      log.log("recordingChange state=", isRecording, "previous", this.isRecording, "event.state", this.event.state);
      if (isRecording == this.isRecording)
        return;
      this.isRecording = isRecording;
      let data = {isRecording};
      if (!closing && ["running", "paused", "starting"].includes(this.event.state))
        data.state = isRecording ? "running" : "paused";
      updateEvent(this.event, data);
      if (!isRecording)
        this.createNewRecording();
    },
    eventSetMerge,
    updateMixer() {
      let mixers = this.audioMixers.slice(0, this.users.length);
      this.users.forEach((u,index) => {
        log.log("updateMixer user=", u);
        let m = mixers[index];
        // Only adds or removes new elements
        if (u && ((u.stream && !m) || m?.stream != u.stream)) {
          mixers[index] = {uid: u.id, userIndex:index, name:u.name, stream:u.stream, gain: 1};
          log.log("Adding new user to mix", mixers[index], index);
        }
        else if (m && (!u || !u.stream || u.id != m.uid)) {
          log.log("Removing user to mix", m, index);
          delete mixers[index];
        }
      });
      log.log("updateMixer", mixers);
      this.audioMixers = mixers;
    },
    updateGain(stream, value) {
      this.$refs.composer?.merger?.updateGain(stream, value);
    },
    reSendMixer() {
      log.log("reSendMixer")
      this.audioMixers.forEach(m => {
        if (m)
          this.updateGain(m.stream, m.gain);
      });
    },
    async setAudienceRooms(value) {
      if (!value)
        await eventSetMerge(this.event, {workflowOptions:{
          audienceMultiRoomOptions:null,
          hideAudienceUserList:false
        }});
      else {
        let roomCollection = `LiveEvents/${this.event.id}/rooms`;
        let config = {
          numRooms: 4,
          autoJoin: true,
          roomCollection,
          roomOptions: {
            video: true,
            canChat: true,
          }
        };
        await eventSetMerge(this.event, {workflowOptions:{
          audienceMultiRoomOptions:config,
          hideAudienceUserList:true
        }});
        for (let i = 0; i < config.numRooms; ++i) {
          let id = `${this.event.id}room${i}`;
          createOrOverride(db.collection(roomCollection), id, Object.assign({
            name: `Room ${i}`,
          }, config.roomOptions));
        }
      }
    }
  }
}
</script>

<style scoped>

</style>