<template>
  <div ref="top">
    <div class="guest">
      <b-alert variant="warning" show v-if="errorMsg">
        Error: {{ errorMsg }}
      </b-alert>
      <div v-if="$debug.isOn" class="mb-1">
        <debug-obj label="event" :objData="event" folded/>
        <debug-obj label="eventUser" :objData="eventUser" :folded="!$debug.autoAccept"/>
      </div>
      <div v-if="page && $debug.isOn" class="alert-info form-inline sub-mr-2 mb-1">
        <debug-obj label="page" :objData="page"/>
          <b-form-input type="text" small v-model="pageText" placeholder="pageid"/>
          <button class="btn btn-sm btn-primary" @click="setPage()">Set</button>
        <debug-obj label="params" :objData="pageParams"/>
      </div>
      <device-support location="guest"/>
      <b-alert variant="warning" :show="$debug.autoAccept">
        Warning, this user is running in automatted test mode. Do not use with customers.
      </b-alert>
      <!-- Live Room -->
      <div class="liveroom" v-if="roomId">
        <room
          ref="room"
          :roomId="roomId"
          :user="user"
          :roomUsers="eventUsersIds"
          :userOptions="{isModerator, canEditRoom:isModerator, canEditQueue:(eventUser && eventUser.role == 'host'), event, eventUser}"
          @roomJoined="roomJoined"
          @roomLeft="roomLeft"
        />
      </div>
      <!-- Terms of Use -->
      <div class="tou" v-else-if="user && !user.waiverSigned">
        <tou v-on:signToU="signToU" />
      </div>
      <!-- Page -->
      <div v-else-if="event && page">
        <div class="d-flex vh-main">
        <component
          :class="pageClasses[page] || 'col-12 my-auto'"
          :is="page"
          :params="pageParams"
          :user-id="user.id"
          :user="user"
          :event-user="eventUser"
          :event="event"
          :event-id="event.id"
          @join-room="joinRoom"
        />
        </div>
      </div>
      <!-- No state -->
      <div v-else>
        Loading...
      </div>
      <!-- Help chat-box -->
      <div class="fixed-bottom align-right" v-if="$app.showHelpButton">
        <div class="mr-5" v-if="!displayChat">
          <button class="btn btn-primary" @click="displayChat = !displayChat">Help</button>
        </div>
        <div class="p-2 border chat-box bg-white" v-else>
          <div class="pb-2">
            <b>Help Chat</b>
            <button class="btn btn-sm btn-secondary float-right" @click="displayChat = !displayChat">Minimize</button>
          </div>
          <div>Type here to contact support:</div>
          <chat ref="chat"
            :channel="user.id"
            :author="user.name"
            :config="{hideStatus: true, autoScroll:true, limitOverflow:true}"/>
        </div>
      </div>
    </div>
    <awake-video/>
  </div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("guest");
import { db } from "@/services/db";
import { initServerTime } from "@/services/server-time";
import { registerUserToEvent, addUsertoEvent } from "@/services/user";
import { getEventUserBehavior, eventTypeNames } from "@/services/event-manager";
import { chatSendStatus } from "@/services/chat";
import room from "@/components/room";
import Chat from '@/components/chat.vue';
import AwakeVideo from '@/components/awakeVideo.vue';

// Pages
import profile from "@/pages/profileForm";
import tou from "@/pages/tou";
import convFeedback from "@/pages/convFeedback";
import endSurvey from "@/pages/endSurvey";
import atcapacity from "@/pages/atcapacity";
import welcome from "@/pages/welcome";
import mixerProgress from "@/pages/mixerProgress";
import autojoinRoom from "@/pages/autojoinRoom";
import agenda from "@/pages/agenda";
import message from "@/pages/message";
import matches from "./Matches";
import thanks from '@/pages/thanks.vue';
import thanksMeeting from '@/pages/thanksMeeting.vue';
import stage from "@/pages/stage";
import deviceSupport from '@/components/deviceSupport.vue';

export default {
  name: "app",
  components: {
    deviceSupport,
    selectQuestions: () => import("@/pages/selectQuestions"),
    ring: () => import("@/pages/ring"),
    Chat,
    AwakeVideo,
    profile,
    tou,
    convFeedback,
    endSurvey,
    atcapacity,
    welcome,
    message,
    mixerProgress,
    autojoinRoom,
    agenda,
    matches,
    room,
    thanks,
    thanksMeeting,
    stage,
    recordQuestion: () => import("@/pages/recordQuestion"),
    manageQuestions: () => import("@/pages/manageQuestions"),
    meetingView: () => import("@/pages/meetingView.vue"),
  },
  props: {
    propUid: {
      type: String,
      required: false
    },
    manager: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      errorMsg: null,
      event: null,

      // User
      user: null,
      eventUser: null,
      eventUserBehavior: null,
      eventUsers: null,
      
      statePage: null,
      statePageParams: null,

      // Page
      pageClasses: {
        "stage": "w-100",
        "meetingView": " ",
        "endSurvey": " ",
      },

      // Live Room
      roomId: "",

      // Debug
      pageText: "",

      displayChat: false,
    };
  },
  computed: {
    page() {
      return this.statePage || this.eventUser?.page;
    },
    pageParams() {
      if (this.statePage) return this.statePageParams || {};
      return this.eventUser?.pageParams || {};
    },
    isModerator() {
      return (this.$route.path.startsWith("/moderator") 
        || (this.user && this.user.moderator)) && true;
    },
    eventUsersIds() {
      return this.eventUsers?.map(e => e.id) || null;
    }
  },
  async mounted() {
    initServerTime();
    if (this.isModerator) this.name = "Moderator";
    // Load user id
    var uid = null;
    if (this.$route.query.ignoreuid)
      log.log("Ignore localStorage userid");
    else if (this.propUid)
      uid = this.propUid;
    else if (this.$route.query.uid)
      uid = this.$route.query.uid;
    else {
      let suid = localStorage.getItem("userid");
      if (suid) 
        uid = suid;
      else if (this.$app.redirectToSignupFromHomepage)
        this.$router.push("/guest/signup");
    }
    if (uid)
      await this.bindUser(uid);
    this.$parent.hideNav = !this.$parent.user;
    this.$parent.hideTitle = !this.$parent.user;
    // autojoin event
    if (this.$route.params.eventId)
      this.bindEvent(this.$route.params.eventId);
    else if (this.user) {
      // join room
      if (this.$route.query.room)
        this.joinRoom(this.$route.query.room);
      else
        this.errorMsg = `No ${this.$app.eventName.toLowerCase()} selected, please join from the link provided.`;
    }
  },
  methods: {
    signToU(sign) {
      db.collection("LiveUsers")
        .doc(this.user.id)
        .update({ waiverSigned: sign });
    },
    async bindUser(uid) {
      let that = this;
      return new Promise(resolve => {
        that.$bind("user", db.collection("LiveUsers").doc(uid)).then(user => {
          if (!user) {
            log.error(`User ${uid} not found`);
            return;
          }
          document.title =
            (that.isModerator ? "Mod." : "Guest") + " - " + user.name;
          log.log("found user", user);
          resolve(user);
        });
      });
    },
    async bindEvent(eventId) {
      let event = await this.$bind("event", db.collection("LiveEvents").doc(eventId));
      log.log("loaded Event: ", event);
      if (event && event.display) {
        this.$app.eventName = eventTypeNames[event.type] || this.$app.eventName;
        if (event.callOptions?.eventUsers)
          this.$bind("eventUsers", db.collection(`LiveEvents/${event.id}/users`));
        this.bindEventUser(this.user.id);
      }
      else
        this.errorMsg = `Could not load event ${eventId}`;
    },
    async bindEventUser(uid) {
      if (!this.event) {
        log.error("No event selected");
        return;
      }
      let col = db.collection(`LiveEvents/${this.event.id}/users`);
      let eventUser = await this.$bind("eventUser", col.doc(uid));
      registerUserToEvent(this.user, this.event.id);
      addUsertoEvent(this.user.id, this.event);
      log.log("loaded eventUser:", eventUser);
      this.eventUserBehavior = getEventUserBehavior(this.user, this.event, this.eventUser, this);
      if (this.eventUserBehavior) {
        this.$watch("event", this.eventUserBehavior.onEventUpdate.bind(this.eventUserBehavior));
        this.$watch("eventUser", this.eventUserBehavior.onEventUserUpdate.bind(this.eventUserBehavior));
        await this.eventUserBehavior.init();
      }
    },
    async unbindEvent() {
      this.$unbind("event");
      this.$unbind("eventUser");
    },
    setPage() {
      log.log("setPage", this.pageText);
      this.eventUserBehavior.setPage(this.pageText);
    },
    // Live Room
    async joinRoom(roomId) {
      log.log("joining room", roomId);
      this.roomId = roomId;
    },
    async roomJoined() {
      chatSendStatus(this.user.id, "Joined room.", true);
    },
    async roomLeft(params) {
      log.log("roomLeft", params);
      chatSendStatus(this.user.id, "Left room.", true);
      if (this.eventUserBehavior) {
        await this.eventUserBehavior.on("roomLeft", params);
      }
      this.roomId = null;
    },
  }
};
</script>

<style>
/* News */
[name="adapterjs-alert"] {
  visibility: hidden;
}

.align-right {
  right: 0px;
  left: unset;
}

.chat-box {
  width: 300px;
}

</style>