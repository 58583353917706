<template>
<div class="text-center">
  <h1>Thanks for participating to this {{ $app.eventName.toLowerCase() }}!</h1>
  See you next time...
</div>
</template>

<script>
export default {
  props: {
    event: Object
  }
}
</script>

<style>

</style>