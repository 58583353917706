<template>
<div>
  <!-- Host -->
  <div v-if="eventUser.role == 'host'">
    You're the Host.<br/>
    <br/>
    Please share this link with your guests:<br/>
    <shareable :content="`${$app.appURL}/guest/join/${event.id}`"/>
    <br/>
    <preview-gum class="shadow-lg p-2 mb-2 bg-white rounded"/>
    <button class="btn btn-primary mt-2" @click="joinRoom(true)">I'm ready!</button>
  </div>
  <div v-else>
    <!-- Queue -->
    <div v-if="event.workflowOptions.entranceMode == 'queue'">
      <div v-if="eventUser.queuePosition == event.queueIndex">
        You're next in line.<br/>
        <preview-gum class="shadow-lg p-2 mb-2 bg-white rounded"/>
        <button class="btn btn-primary mt-2" @click="joinRoom">Join Room</button>
      </div>
      <div v-else-if="eventUser.queuePosition > event.queueIndex">
        You're number {{ eventUser.queuePosition - event.queueIndex + 1 }} in line.
      </div>
    </div>
    <!-- Standard Meeting -->
    <div v-else>
      Welcome to {{ event.title }}
      <preview-gum class="shadow-lg p-2 mt-2 mb-2 bg-white rounded"/>
      <button class="btn btn-primary mt-2" @click="joinRoom">I'm ready!</button>
    </div>
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
import shareable from '@/components/shareable';
import previewGum from "@/components/previewGum";
let log = getLog("autojoinRoom");

export default {
  components: { 
    shareable,
    previewGum,
  },
  props: {
    event: Object,
    eventUser: Object,
    params: Object,
  },
  data() {
    return {
    }
  },
  async mounted() {
    log.log("mounted", this.params);
  },
  methods: {
    joinRoom(host) {
      log.log("joinRoom", host, this.params);
      this.$parent.eventUserBehavior.on("joinRoom", {roomId:this.params.roomId});
    }
  }
}
</script>

<style>

</style>