import { chatSendStatus, chatDelete } from "./chat";
import { createRoomWithData, sendRoomLink } from "./room";
import { addMatches, removeUserFromEvent } from "./user";
import { formatQuestions, arraysEqual, shuffleArray, getKeyByValue, removeNull, getKeysByValue, trueOrIncludes } from "./utils";
import { db, serverTimestamp, fieldDelete } from "./db";
import { getCollectionDocsFromIds, loadObj, loadArr, loadMap } from "./dbutils";
import { getLog } from "./log";
let log = getLog('lib-events');

export let eventTypes = [
  {
    "type": "stage",
    "name": "Show",
    "desc": "Produce a show with guests. Record or stream live.",
    "showShortName": true,
    "template": "default_stage",
    "creatorRole": "moderator",
    "beta": true,
  },
  {
    "type": "meeting",
    "name": "Meeting",
    "desc": "Standard video call.",
    "creatorRole": "host",
    "template": "default_meeting",
    "showShortName": true,
    "beta": true,
  },
  {
    "type": "queue",
    "name": "Office Hours",
    "desc": "Schedule a series of 1:1 calls.",
    "showShortName": true,
    "creatorRole": "host",
    "template": "default_call",
    "beta": true,
  },  
  {
    "type": "videoQnA",
    "name": "Video Q&A",
    "desc": "Get your audience ask video questions to use on your show.",
    "template": "default_video_qna",
    "beta": true,
  },
  {
    "type": "matchmaking",
    "name": "Mixer",
    "desc": "For groups to get into a rotation of 1:1 calls.",
    "template": "default_pro",
    "beta": true,
  },
];

export let eventTypeNames = (() => {
  let res = {};
  eventTypes.forEach(e => {
    res[e.type] = e.name
  });
  return res;
})();

function isConversationEqual(c1, c2) {
  let res = (c1.u1.id == c2.u1.id && c1.u2.id == c2.u2.id) 
    || (c1.u2.id == c2.u1.id && c1.u1.id == c2.u2.id);
  return res;
}

function matchGenderInterest(u1, u2) {
  return (u1.gender == u2.interest || u2.interest == "b")
    && (u2.gender == u1.interest || u1.interest == "b");
}

export class EventManager {
  // Set internal variables (need to restore from closed)
  async init(eventId, useListeners) {
    this.updateCallback = () => {};

    this.event = await loadObj(db.collection("LiveEvents").doc(eventId));
    await this.setUserList(this.event.userIds);

    // Connected to data
    this.eventUserCollection = db.collection(`LiveEvents/${this.event.id}/users`);
    this.eventUsers = await loadMap(this.eventUserCollection);

    // Started conversations
    this.eventConvCollection = db.collection(`LiveEvents/${this.event.id}/conversations`);
    this.eventConversations = await loadMap(this.eventConvCollection);

    this.eventConvExcludedCollection = db.collection(`LiveEvents/${this.event.id}/excluded`);
    this.excluded = await loadArr(this.eventConvExcludedCollection);

    this.eventMatchesCollection = db.collection(`LiveEvents/${this.event.id}/matches`);
    this.matches = await loadMap(this.eventMatchesCollection);
 
    // only if running on browser
    if (useListeners)
      this.initListeners();

    // In memory
    this.rounds = null;
    this.roundsInfo = {};
    this.isReady = true;
  }

  async setUserList(userIds) {
    if (!userIds)
      userIds = [];
    if (!this.userIds)
      this.userIds = [];
    if (arraysEqual(userIds, this.userIds))
      return;
    log.log("Updating user list", userIds);
    let users = await getCollectionDocsFromIds(db.collection("LiveUsers"), userIds);
    let usersMap = {};
    users.forEach((user) => { 
      usersMap[user.id] = user;
    });
    this.users = users;
    this.usersMap = usersMap;
    this.userIds = userIds;
  }

  // Called only on browser
  initListeners() {
    this.eventListener = db.collection(`LiveEvents`).doc(this.event.id).onSnapshot((doc) => {
      log.log(`event.onSnapshot ${doc.id}`, {level:0});
      this.event = { ...doc.data(), id:doc.id };
      this.setUserList(this.event.userIds);
    });

    this.eventUserCollectionListener = this.eventUserCollection.onSnapshot((snap) => {
      log.log("eventUserCollection.onSnapshot");
      snap.docChanges().forEach((change) => {
        let doc = change.doc;
        if (change.type == "removed")
          delete this.eventUsers[doc.id];
        else
          this.eventUsers[doc.id] = { ...doc.data(), id:doc.id };
      });
    });

    this.eventConvCollectionListener = this.eventConvCollection.onSnapshot((snap) => {
      log.log("eventConvCollection.onSnapshot");
      snap.docChanges().forEach((change) => {
        let doc = change.doc;
        if (change.type == "removed")
          delete this.eventConversations[doc.id];
        else
          this.eventConversations[doc.id] = { ...doc.data(), id:doc.id };
      });
    });

    this.eventMatchesCollectionListener = this.eventMatchesCollection.onSnapshot((snap) => {
      log.log("eventMatchesCollection.onSnapshot");
      snap.docChanges().forEach((change) => {
        let doc = change.doc;
        if (change.type == "removed")
          delete this.matches[doc.id];
        else
          this.matches[doc.id] = { ...doc.data(), id:doc.id };
      });
    });    
  }

  release() {
    if (this.eventListener) this.eventListener();
    if (this.eventUserCollectionListener) this.eventUserCollectionListener();
    if (this.eventConvCollectionListener) this.eventConvCollectionListener();
    if (this.eventMatchesCollectionListener) this.eventMatchesCollectionListener();
  }

  async updateEvent(data) {
    await db.collection("LiveEvents").doc(this.event.id).update(data);
  }

  // Reset Event
  resetEvent() {
    resetEvent(this.event, this.eventUsers);
    this.resetEventUserData();
    this.rounds = null;
    this.roundsInfo = {};
    this.eventComplete = false;
    this.updateCallback("rounds", null);
    this.updateCallback("dbgConversations", null);
  }

  resetEventUserData() {
    for (let u of this.users) {
      chatDelete(u.id);
    }
    for (let cid in this.eventConversations) {
      this.eventConvCollection.doc(cid).delete();
    }
    this.eventConversations = {};
    for (let ex of this.excluded) {
      this.eventConvExcludedCollection.doc(ex.id).delete();
    }
    this.excluded = [];
    for (let mid in this.matches) {
      this.eventMatchesCollection.doc(mid).delete();
    }
    this.matches = {};
  }

  resetReady() {
    for (let u of this.users) {
      this.eventUserCollection.doc(u.id).update({status:"ready"});
    }
  }

  // Event Management ------------------------------------------------------
  changeState(state) {
    log.log("Event Manager changeState", state);
    db.collection(`LiveEvents`).doc(this.event.id).update({state:state});
    // 10 mins before the event
    if (state == "opendoors") {
      db.collection(`LiveEvents`).doc(this.event.id).update({
        doorsClosed:false,
      });
    } else if (state == "closeddoors") {
      let users = this.users.map((u) => u.id);
      let eventUsers = Object.keys(this.eventUsers);
      log.log("Users in:", users, eventUsers);
      db.collection(`LiveEvents`).doc(this.event.id).update({
        doorsClosed:true,
        userIds:users
      });
      this.updateConversationAndRounds();
    } else if (state == "running") {
      //
    } else if (state == "closed") {
      //
    }
  }

  // Automation
  checkNextStep() {
    if (this.event.pauseCheckNextStep) {
      log.log("pauseCheckNextStep");
      return;
    }
    if (this.event.type == "matchmaking") {
      this.checkNextStepMatchmaking();
    }
  }

  async checkNextStepMatchmaking() {
    let isClient = typeof window !== "undefined";
    let runHere = (this.event.runningOnClient && isClient) || (!this.event.runningOnClient && !isClient);
    // config on how next steps should behave
    let config = {
      run: this.event.state != null && this.event.state != "closed",
      calculateRounds: this.event.state == "closeddoors" || this.event.state == "running",
      createEventUser: false,
      sendResponse: (this.event.state == "opendoors" || this.event.state == "closeddoors" || this.event.state == "running") && runHere,
      sendQuestionnaire: (this.event.state == "running") && runHere,
      startCalls: this.event.state == "running" && runHere && (!isClient || !this.event.moderatorStartingCall),
      sendEndOfEvent: this.event.state == "running" && runHere,
      sendSurvey: this.event.state == "running" && runHere,
    }
    log.log(`EventManager checkNextStep`, {config, runHere});
    if (!config.run)
      return;
    if (config.calculateRounds && !this.rounds) {
      log.log("Check conversation rounds are computed")
      this.updateConversationAndRounds();
    }
    if (config.startCalls) {
      this.updateInProgressConvs();
      this.checkNextStepConversations();
      this.checkEventComplete();
    }
  }

  checkNextStepConversations() {
    log.log("checkNextStepConversations")
    let status = this.rounds.map(r => r.conversations.map(c => this.convStatus(c)));
    log.log("statuses", JSON.stringify(status));
    if (this.event.startRoundWhenAllReady) {
      var i = 0;
      let len = this.rounds.length;
      for (; i < len; ++i) {
        if (!this.allConvsCompleteInRound(this.rounds[i]))
          break;
      }
      if (i >= len) {
        log.log("reached last round");
      } else {
        log.log(`next round: ${i}`);
        if (this.canStartAllConvInRound(this.rounds[i]))
          this.startRound(this.rounds[i]);
        else
          log.log(`Can't start round ${i} not all users are ready`);
      }
    }
    else {
      for (let r of this.rounds) {
        if (this.canStartRound(r)) {
          this.startRound(r);
          break;
        }
      }
    }
  }

  checkEventComplete() {
    log.log("checkEventComplete");
    this.eventComplete = Object.values(this.eventUsers).every(eu => (eu.status == "complete"));
    if (this.eventComplete) {
      log.log("** EVENT COMPLETE **");
      if (!this.event.eventComplete)
        db.collection(`LiveEvents`).doc(this.event.id).update({eventComplete:true});
    }
  }

  evaluateNumConversation(uid) {
    var count = 0;
    for (let r of this.rounds)
      for (let c of r.conversations)
        if (uid == c.u1.id || uid == c.u2.id)
          count += 1;
    return count;
  }

  evaluateNumConversationForAllUsers() {
    for (let u of this.users) {
      let mc = Math.min(this.evaluateNumConversation(u.id), this.event.maxConversationPerUser);
      this.eventUserCollection.doc(u.id).update({"maxConversations": mc});
    }
  }

  // Conversations ------------------------------------------
  startRound(r) {
    log.log("Starting round");
    for (let c of r.conversations)
      this.startConv(c);
    this.sendMessageToSitting(r.sitting)
  }

  formatContentText(c) {
    let res = "";
    let ue1 = this.eventUsers[c.u1.id];
    let ue2 = this.eventUsers[c.u2.id];
    if (ue1.questions)
      res += c.u1.name + '\'s questions:\n' + formatQuestions(ue1.questions);
    if (ue2.questions)
      res += "\n\n" + c.u2.name + '\'s questions:\n' + formatQuestions(ue2.questions);
    return res;
  }

  formatContent(c) {
    let res = {type:"questions"};
    let ue1 = this.eventUsers[c.u1.id];
    let ue2 = this.eventUsers[c.u2.id];
    let q1 = ue1.questions ? ue1.questions.map((q) => { return {question:q, author:c.u1.name} }) : [];
    let q2 = ue2.questions ? ue2.questions.map((q) => { return {question:q, author:c.u2.name} }) : [];
    res.questions = q1.map(
      (element, index) => [element, q2[index]]
    ).flat().filter(q => q);
    return res;
  }

  canStartConv(c) {
    return this.eventUsers[c.u1.id]
      && this.eventUsers[c.u1.id].status == "ready"
      && this.eventUsers[c.u1.id].conversationCount < this.event.maxConversationPerUser
      && this.eventUsers[c.u2.id]
      && this.eventUsers[c.u2.id].status == "ready"
      && this.eventUsers[c.u2.id].conversationCount < this.event.maxConversationPerUser
      ;
  }

  simplifyConv(c) {
    return {id:c.id, name:c.name,
      u1:{id:c.u1.id, name:c.u1.name}, 
      u2:{id:c.u2.id, name:c.u2.name},
    };
  }

  updateInProgressConvs() {
    log.log("updateInProgressConvs");
    for (let cid in this.eventConversations) {
      let c = this.eventConversations[cid];
      // From progress to completed.
      if (c.status == 'inprogress') {
        log.log("conv in progress", c);
        if (this.eventUsers[c.u1.id].status != 'ready'
          && this.eventUsers[c.u2.id].status != 'ready')
          this.eventConvCollection.doc(c.id).update({'status':'completed'});
      }
    }
  }

  async startConv(c) {
    log.log("startConv", c);
    let u1cc = this.eventUsers[c.u1.id].conversationCount ? this.eventUsers[c.u1.id].conversationCount+1 : 1;
    let u2cc = this.eventUsers[c.u2.id].conversationCount ? this.eventUsers[c.u2.id].conversationCount+1 : 1;
    let timer = (u1cc == 1 || u2cc == 1) ? this.event.firstCallTimer : this.event.callTimer;
    let users = [ c.u1.id, c.u2.id ];
    let bot = this.event.botId;
    let content = this.event.workflowOptions.useQuestions ? this.formatContent(c) : '';
    let room = await createRoomWithData(Object.assign({
      name: c.name,
      users,
      timer,
      content,
      bot,
      conversationId: c.id
    }, this.event.callOptions));
    sendRoomLink(room.id, users);
    this.eventUserCollection.doc(c.u1.id).update({status:"busy",page:"mixerProgress",pageParams:{roomId:room.id, otherId:c.u2.id},conversationCount:u1cc});
    this.eventUserCollection.doc(c.u2.id).update({status:"busy",page:"mixerProgress",pageParams:{roomId:room.id, otherId:c.u1.id},conversationCount:u2cc});
    let sc = this.simplifyConv(c);
    sc.status = "inprogress";
    sc.roomId = room.id;
    sc.start = serverTimestamp();
    this.eventConvCollection.doc(c.id).set(sc);
  }

  sendMessageToSitting(sitting) {
    for (let u of sitting) {
      let eu = this.eventUsers[u.id];
      if (eu.conversationCount < eu.maxConversations) {
        this.eventUserCollection.doc(eu.id).update({
          status:"ready",
          page:"message", 
          pageParams:{
            message: `We have an uneven group. You will be sitting for this round, please wait for your next call to start in about ${Math.floor(this.event.callTimer)} minutes...`
          },
        });
        chatSendStatus(eu.id, "Sitting.");
      }
    }
  }

  excludeConv(c) {
    let that = this;
    this.eventConvExcludedCollection.doc(c.id).set(this.simplifyConv(c)).then(() => {
      that.updateConversationAndRounds();
    });
  }

  removeExcludedConv(c) {
    let that = this;
    this.eventConvExcludedCollection.doc(c.id).delete().then(() => {
      that.updateConversationAndRounds();
    });
  }

  allConvsCompleteInRound(r) {
    for (let c of r.conversations)
      if (!this.convStatus(c))
        return false;
    return true;
  }

  canStartAllConvInRound(r) {
    for (let c of r.conversations)
      if (!this.canStartConv(c))
        return false;
    return true;
  }

  canStartRound(r) {
    for (let c of r.conversations)
      if (this.convStatus(c) || !this.canStartConv(c))
        return false;
    return true;
  }

  convStatus(c) {
    return this.eventConversations[c.id]?.status;
  }

  // Conversation Generation ----------------------------------------------
  updateConversationAndRounds() {
    log.log("updateConversationAndRounds");
    if (this.event.everyoneInterestBoth) {
      this.rounds = this.computeRoundRobbinEveryone();
    } else {
      this.rounds = this.computeRoundRobbin();
    }
    this.updateCallback("rounds", this.rounds);
    db.collection(`LiveEvents`).doc(this.event.id).update({
      rounds:this.rounds, 
      roundsInfo:this.roundsInfo,
    });
    this.evaluateNumConversationForAllUsers();
    log.log("updateConversationAndRounds", this.rounds, this.roundsInfo);
  }

  // Brute force handles:
  // - gender/interest
  // - exclusions
  // - maxConversation
  computeAndDivide() {
    log.log("computeAndDivide");
    let conversations = this.computeConversations();
    let filtered = conversations.filter(c => !this.excluded.find(ex => isConversationEqual(c, ex)));
    this.updateCallback("dbgConversations", filtered);
    let rounds = [];
    if (this.users.length % 2 == 0 && this.event.everyoneInterestBoth) {
      log.log("even number of users");
      let count = 0;
      do {
        log.log(`Shuffling until matches ideal combination of conversation ${count++}`);
        shuffleArray(filtered);
        rounds = this.divideConversations(filtered);
        this.roundsInfo.algorithm = "computeAndDivide-shuffling (non stable)";
      } while (rounds.length >= this.users.length && count <= 10);
      if (count > 10) {
        log.error("Shuffling iteration limit broken");
        this.roundsInfo.algorithm = "computeAndDivide-shuffling-error (non stable)";
      }
    } else {
      log.log("odd number of users");
      rounds = this.divideConversations(filtered);
      rounds.sort((r1, r2) => r2.length - r1.length);  
      this.roundsInfo.algorithm = "computeAndDivide";
    }
    return rounds;
  }

  computeConversations() {
    let res = [];
    if (this.eventUsers.length < 2) {
      log.error("computeConversations error: not enough users to compute.");
      return;
    }
    let users = Object.values(this.eventUsers);
    //log.log("before array", users.map(u => u.name));
    users.sort((u1, u2) => u1.gender.localeCompare(u2.gender));
    //log.log("sorted array", users.map(u => u.name));
    let len = users.length;
    for (let i1 = 0; i1 < len; ++i1) {
      let u1 = users[i1];
      res.push([]);
      for (let i2 = i1; i2 < len; ++i2) {
        let u2 = users[i2];
        if (u1.id != u2.id && matchGenderInterest(u1, u2)) {
          res[i1].push({ 
            id:`${u1.id}_${u2.id}`, 
            name: `${u1.name}/${u2.name}`,
            u1:u1, 
            u2:u2
          });
        }
      }
    }
    let r = [];
    while (res[0].length > 0) {
      for (let i = 0; i < len; ++i) {
        let c = res[i].shift();
        if (c)
          r.push(c);
      }
    }
    return r;
  }

  divideConversations(conversations) {
    let convCount = new Map();
    let taken = new Map();
    let rounds = [];
    let remainingConversations = [...conversations];
    let len = remainingConversations.length;
    do {
      var round = [];
      for (let i = 0; i < len; ++i) {
        let c = remainingConversations[i];
        if (c
          && !taken[c.u1.id]
          && !taken[c.u2.id]
          && (convCount[c.u1.id] || 0) < this.event.maxConversationPerUser
          && (convCount[c.u2.id] || 0) < this.event.maxConversationPerUser) {
         round.push(c);
         taken[c.u1.id] = true;
         taken[c.u2.id] = true;
         convCount[c.u1.id] = (convCount[c.u1.id] || 0) + 1;
         convCount[c.u2.id] = (convCount[c.u2.id] || 0) + 1;
         remainingConversations[i] = null;
        }
      }
      if (round.length > 0) {
        //log.log("pushed round", round);
        rounds.push({ 
          conversations: round, 
          sitting: this.users.filter(u => !taken[u.id]) 
        });
        taken = new Map();
      }
    } while (round.length > 0);
    //log.log("res=", rounds);
    return rounds;
  }

  isSameNumberOfMenAndWomen() {
    let users = Object.values(this.eventUsers);
    let women = users.filter(u => u.gender == "f");
    let men = users.filter(u => u.gender == "m");
    return (women.length == men.length) && !this.excluded.length;
  }

  // Works with m/f groups (Now supports uneven groups - sitting)
  computeRoundRobbinEveryone() {
    log.log("Using Round Robbin Everyone (Ignores exclusions)");
    let users = Object.values(this.eventUsers);
    let half = users.length / 2;
    let groupA = users.splice(0, half);
    groupA.sort((a, b) => a.name.localeCompare(b.name));
    let groupB = users.sort((a, b) => a.name.localeCompare(b.name));
    let rounds = this.rrCombineGroups(groupA, groupB);
    this.roundsInfo.algorithm = "computeRoundRobbinEveryone-no-exclusions";
    return rounds;
  }
  // Works with m/f groups (Now supports uneven groups - sitting)
  computeRoundRobbin() {
    log.log("Using Round Robbin (Ignores exclusions)");
    let users = Object.values(this.eventUsers);
    let groupA = users.filter(u => u.gender == "f").sort((a, b) => a.name.localeCompare(b.name));
    let groupB = users.filter(u => u.gender == "m").sort((a, b) => a.name.localeCompare(b.name));
    let rounds = this.rrCombineGroups(groupA, groupB);
    this.roundsInfo.algorithm = "computeRoundRobbin-no-exclusions";
    return rounds;
  }

  rrCombineGroups(groupA, groupB) {
    let minlen = Math.min(groupB.length, groupA.length);
    let maxlen = Math.min(Math.max(groupB.length, groupA.length), this.event.maxConversationPerUser);
    let rounds = [];
    for (let ir = 0; ir < maxlen; ++ir) {
      let round = [];
      for (let i = 0; i < minlen; ++i) {
        let um = groupB[i];
        let uw = groupA[i];
        if (um.id != uw.id)
          round.push({
            id: `${uw.id}_${um.id}`,
            name: `${uw.name}/${um.name}`,
            u1: uw,
            u2: um
          });
      }
      let sitting = [...groupB.slice(minlen), ...groupA.slice(minlen)];
      if (groupA.length > groupB.length)
        groupA.push(groupA.shift());
      else
        groupB.push(groupB.shift());
      if (round.length > 0)
        rounds.push({ conversations: round, sitting: sitting });
    }
    return rounds;
  }
}

// ----------------------------------------------------------------------------
// Utility functions
// ----------------------------------------------------------------------------

// Mixer
function resetEventUserFromUser(event, u) {
  log.log("resetEventUserFromUser");
  let value = {status:"start", page:"welcome", conversationCount:0};
  value.name = u.name;
  value.icon = u.picture0;
  value.gender = u.gender || "o";
  value.interest = u.interest || "b";
  if (event.everyoneInterestBoth)
    value.interest = "b";
  else if (event.interestToOppositeGender && u.interest == "b")
    value.interest = u.gender == "m" ? "f" : "m";
  db.collection(`LiveEvents/${event.id}/users`).doc(u.id).set(value);
}

async function computeMatchesForUserId(event, uid) {
  let res = [];
  let matches = await loadArr(db.collection(`LiveEvents/${event.id}/matches`));
  log.log("computeMatches", matches);
  for (let m of Object.values(matches)) {
    let [u1, u2] = m.id.split("_");
    if (u1 == uid && m.pick) {
      for (let m2 of Object.values(matches)) {
        let [m2u1, m2u2] = m2.id.split("_");
        if (m2u1 == u2 && m2u2 == u1 && m2.pick) {
          log.log(`found match: ${u1}, ${u2}`);
          res.push(u2);
        }
      }
    }
  }
  if (res.length > 0) {
    // Add to user profiles
    let profiles = await getCollectionDocsFromIds(db.collection(`LiveUsers`), res);
    addMatches(uid, profiles, event.id);
  }
}

// Queue
export async function resetEvent(event, eventUsers) {
  // Delete event properties
  updateEvent(event, {
    state:fieldDelete(),
    doorsClosed:fieldDelete(),
    lastUpdate:fieldDelete(),
    eventComplete:fieldDelete(),
    lastUpdateEnd:fieldDelete(),
    // matchmaking
    rounds:fieldDelete(),
    computeInfo:fieldDelete(),
    // queue
    queueLength:fieldDelete(),
    queueIndex:fieldDelete(),
    lastQueueIndex:fieldDelete(),
    lastQueueUser:fieldDelete(),
    roomId:fieldDelete(),
  });
  // Delete event users
  let eventUserCollection = db.collection(`LiveEvents/${event.id}/users`);
  for (let ueid in eventUsers) {
    eventUserCollection.doc(ueid).delete();
  }
  eventUsers = {};
}

export async function updateEvent(event, data) {
  return await db.collection("LiveEvents").doc(event.id).update(data);
}

export async function eventSetMerge(event, data) {
  return await db.collection("LiveEvents").doc(event.id).set(data, {merge:true});
}

export async function updateRoom(roomId, data) {
  return await db.collection("LiveRooms").doc(roomId).update(data);
}

async function createMeetingRoom(event, name="MeetingRoom", field='roomId') {
  let r = await createRoomWithData(Object.assign({
    name,
    users:[],
  }, event.callOptions));
  await updateEvent(event, {[field]: r.id});
}

async function createStageRoom(event) {
  let moderators = getKeysByValue(event.userRoles, "moderator");
  let speakers = getKeysByValue(event.userRoles, "speaker");
  let r = await createRoomWithData(Object.assign({
    name:"ShowRoom",
    users:[...moderators, ...speakers],
  }, event.callOptions));
  await updateEvent(event, {roomId:r.id});
}

async function updateStageRoom(event, users) {
  if (!users) {
    let moderators = getKeysByValue(event.userRoles, "moderator");
    let speakers = getKeysByValue(event.userRoles, "speaker");
    users = [...moderators, ...speakers];
  }
  log.log(`updateStageRoom ${users}`);
  await updateRoom(event.roomId, {users});  
}

export async function moveEventUserQueuePosition(event, orderedEventUsers, index, dir) {
  log.log(`moveEventUserQueuePosition index:${index} dir:${dir}`);
  let swapIndex = index + dir;
  if (swapIndex < 0 || swapIndex >= event.queueLength) {
    log.log("hit a limit");
    return;
  }
  let col = db.collection(`LiveEvents/${event.id}/users`);
  let indexQueuePosition = orderedEventUsers[index].queuePosition;
  let p1 = col.doc(orderedEventUsers[index].id).update({queuePosition:orderedEventUsers[swapIndex].queuePosition});
  let p2 = col.doc(orderedEventUsers[swapIndex].id).update({queuePosition:indexQueuePosition});
  await Promise.all([p1, p2]);
}

export async function compactQueue(event, orderedEventUsers) {
  log.log("compactQueue");
  let batch = db.batch();
  let col = db.collection(`LiveEvents/${event.id}/users`);
  let index = 0;
  orderedEventUsers.forEach((eu) => {
    if (eu.queuePosition === undefined)
      return;
    if (eu.queuePosition == event.queueIndex) {
      batch.update(db.collection("LiveEvents").doc(event.id), {queueIndex:index});
    }
    batch.update(col.doc(eu.id), {queuePosition:index});
    index += 1;
  });
  batch.update(db.collection("LiveEvents").doc(event.id), {queueLength:orderedEventUsers.length});
  batch.commit();
}

// Adding user to the 1:1 room with host
export async function sendEventUserIntoRoom(event, eu) {
  log.log("sendEventUserIntoRoom");
  if (!event.userRoles) {
    log.log("Need to select a host");
    return false;
  }
  let hostId = getKeyByValue(event.userRoles, "host");
  if (!hostId) {
    log.log("No host found");
    return false;
  }
  if (eu.id == hostId) {
    log.log("Host can't meet themselves!");
    return false;
  }
  let roomId = event.roomId;
  let data = {status: "incall", page:"autojoinRoom", pageParams:{roomId}};
  log.log("eventUserCollection.doc(eu.id).update", data);
  let eventUserCollection = db.collection(`LiveEvents/${event.id}/users`);
  await eventUserCollection.doc(eu.id).update(data);
  let roomData = {users:[hostId, eu.id], content: {type:"agenda", agenda: eu.agenda || ""}};
  removeNull(roomData);
  log.log(`db.collection(LiveRooms).doc(${roomId}).update`, roomData);
  await db.collection("LiveRooms").doc(roomId).update(roomData);
  await sendRoomLink(roomId, [eu.id]);
  await updateEvent(event, {queueIndex: eu.queuePosition, lastQueueUser: eu.id});
  return true;
}

export async function clearRoom(event) {
  log.log("clearRoom");
  if (!event.userRoles) {
    log.log("Need to select a host");
    return false;
  }
  let hostId = getKeyByValue(event.userRoles, "host");
  if (!hostId) {
    log.log("No host found");
    return false;
  }
  let roomId = event.roomId;
  if (event.lastQueueUser) {
    let data = {status: "complete", page:"agenda", pageParams:{}};
    log.log("eventUserCollection.doc(lastQueueUser).update", data);
    let eventUserCollection = db.collection(`LiveEvents/${event.id}/users`);
    await eventUserCollection.doc(event.lastQueueUser).update(data);
  }
  let roomData = {users:[hostId], content: ""};
  removeNull(roomData);
  log.log("db.collection(LiveRooms).doc(roomId).update", roomData);
  await db.collection("LiveRooms").doc(roomId).update(roomData);
  await updateEvent(event, {queueIndex: null, lastQueueUser:null});
  return true;  
}

export async function updateEventUser(eventId, uid, data) {
  await db.collection(`LiveEvents/${eventId}/users`).doc(uid).update(data);
}

export async function deleteEventUser(event, uid) {
  log.log(`delete eventUser ${uid}`);
  await db.collection(`LiveEvents/${event.id}/users`).doc(uid).delete();
}

//-----------------------------------------------------------------------------
// Event Behaviors
//-----------------------------------------------------------------------------
class EventUserBehavior {
  constructor(user, event, eventUser, view) {
    this.user = user;
    this.event = event;
    this.eventUser = eventUser;
    this.eventUserCollection = db.collection(`LiveEvents/${this.event.id}/users`);
    this.view = view;
  }

  setPage(page, params) {
    log.log("setting page to:", page, params);
    this.view.$set(this.view, "statePage", page);
    this.view.$set(this.view, "statePageParams", params);
  }

  onEventUpdate(event) {
    let oldEvent = this.event;
    this.event = event;
    if (oldEvent?.state != event?.state)
      this.on('eventStateChanged', {old:oldEvent?.state, value:event?.state});
  }

  onEventUserUpdate(eventUser) {
    this.eventUser = eventUser;
  }
}

// ----------------------------------------------------------------------------
class MeetingBehavior extends EventUserBehavior {
  async init() {
    log.log("MeetingBehavior.init")
    if (!this.event.roomId)
      await createMeetingRoom(this.event);
    if (!this.event.audienceRoomId)
      await createMeetingRoom(this.event, "Audience Room", 'audienceRoomId');
    let role = this.event.userRoles ? this.event.userRoles[this.user.id] || null : null;
    if (role != 'host' && this.event.workflowOptions.entranceMode == 'queue') {      
      if (!this.eventUser) {
        let data = {page:"agenda", name:this.user.name, image:this.user.picture0};
        await this.eventUserCollection.doc(this.user.id).set(data, {merge:true});
      }
    } else { // 'host'
      let data = {
        page:"autojoinRoom", 
        pageParams:{roomId:this.event.roomId, autoJoin:true}, 
        role, 
      };
      await this.eventUserCollection.doc(this.user.id).set(data, {merge:true});
    }
  }
  async on(event, params) {
    log.log("MeetingBehavior.on", event);
    if (event == 'roomLeft') {
      this.setPage("thanksMeeting");
      await this.eventUserCollection.doc(this.user.id).delete();
    }
    else if (event == 'joinRoom') {
      this.setPage('meetingView', params);
    }
  }
}

// ----------------------------------------------------------------------------
export function getEventUserBehavior(user, event, eventUser, view) {
  if (!event) {
    log.log("no event, no behavior");
    return;
  }
  if (event.type == "matchmaking") {
    return new MatchMakingGuestBehavior(user, event, eventUser, view);
  } else if (event.type == "stage") {
    return new StageBehavior(user, event, eventUser, view);
  } else if (event.type == "meeting") {
    return new MeetingBehavior(user, event, eventUser, view);
  } else if (event.type == "video_qna") {
    if (event.userRoles[user.id] == "moderator")
      return new StageBehavior(user, event, eventUser, view);
    else 
      return new VideoQnAGuestBehavior(user, event, eventUser, view);
  }
  return null;
}

// ----------------------------------------------------------------------------
class MatchMakingGuestBehavior extends EventUserBehavior {
  async init() {
    log.log("MatchMakingGuestBehavior.init");
    if (!this.event.state) {
      this.setPage("message", { message: "The event has not started yet, please come back later." });
    }
    else if (!this.eventUser) {
      if (this.event.doorsClosed) {
        this.setPage("atcapacity");
        removeUserFromEvent(this.user.id, this.event);
      } else {
        resetEventUserFromUser(this.event, this.user);
      }
    }
    else {
      // Recover current state
      if (this.event.state == 'running') {
        if (!this.eventUser.conversationCount)
          this.on('forceStart');
        else 
          this.on('feedbackGiven');
      }
    }
  }
  async on(event, params) {
    log.log("MatchMakingGuestBehavior.on", event, params);
    // Start
    if (event == 'forceStart'
      || event == 'eventStateChanged' && params.value == 'running') {
      if (trueOrIncludes(this.event.workflowOptions.useQuestions, this.eventUser.gender)) {
        chatSendStatus(this.user.id, "Select questions.");
        this.eventUserCollection.doc(this.user.id).update({ status:"busy", page:"selectQuestions" });
      } else
        this.eventUserCollection.doc(this.user.id).update({ status:"ready", page:"mixerProgress" });
    }
    // Event reboot
    else if (event == 'eventStateChanged' && !params.old) {
      this.init();
    }
    else if (event == 'questionsSelected') {
      chatSendStatus(this.user.id, "Questions recorded.");
      this.eventUserCollection.doc(this.user.id).update({ status:"ready", page:"mixerProgress", pageParams: {
        message: "Your questions were recorded. Thank you! Get ready for your first call."
      }});
    }
    else if (event == 'roomLeft') {
      if (this.event.workflowOptions.askFeedback && params.pastWarning) {
        chatSendStatus(this.user.id, "Asking feedback.");
        this.eventUserCollection.doc(this.user.id).update({ status:"callended", page:"convFeedback" });
      }
      else {
        this.on('feedbackGiven');
      }
    }
    else if (event == 'feedbackGiven') {
      if (this.eventUser.conversationCount < this.eventUser.maxConversations) 
        this.eventUserCollection.doc(this.user.id).update({ status:"ready", page:"mixerProgress" });
      else {
        if (this.event.postSurveyLink && !this.eventUser.surveyComplete) {
          chatSendStatus(this.user.id, "Showing survey.");
          this.eventUserCollection.doc(this.user.id).update({ status:"survey", page:"endSurvey", pageParams:null });
        } else {
          this.on('surveyComplete');
        }
      }
    }
    else if (event == 'surveyComplete') {
      if (this.event.workflowOptions.askFeedback && this.event.workflowOptions.showMatches) {
        chatSendStatus(this.user.id, "Showing matches.");
        await computeMatchesForUserId(this.event, this.user.id);
        this.eventUserCollection.doc(this.user.id).update({ status:"complete", page:"matches", pageParams:null });
      } else {
        this.eventUserCollection.doc(this.user.id).update({ status:"complete", page:"thanks", pageParams:null });
      }
    }
    else if (event == 'eventStateChanged' && params.value == 'closed') {
      this.eventUserCollection.doc(this.user.id).update({ status:"complete", page:"thanks", pageParams:null });
    }
  }
}

// ----------------------------------------------------------------------------
class StageBehavior extends EventUserBehavior {
  async init() {
    log.log("StageBehavior.init");
    if (!this.event.roomId)
      await createStageRoom(this.event);
    if (!this.eventUser) {
      let data = {
        page:"stage", 
        shortBio:this.user.shortBio, 
        icon:this.user.picture0, 
        name:this.user.name, 
        role:this.event.userRoles[this.user.id]
      };
      removeNull(data);
      log.log("create eventUser:", data);
      this.eventUserCollection.doc(this.user.id).set(data, {merge:true});
    } else if (this.event.userRoles[this.user.id] && this.eventUser.role != this.event.userRoles[this.user.id]) {
      let data = {
        role:this.event.userRoles[this.user.id]
      };
      log.log("reset role on eventUser:", data);
      this.eventUserCollection.doc(this.user.id).set(data, {merge:true});
    }
  }
  async on(event, params) {
    log.log("eub on", event, params, this.eventUser);
    // Updates stage room when an audience member gets promoted or demoted.
    if (this.eventUser.role == 'moderator' && event == 'changeModeratorsAndSpeakers') {
      let newIds = params;
      updateStageRoom(this.event, newIds);
    }
    // Add connecting flag
    else if (this.eventUser.role == 'speaker' && !this.eventUser.state && event == 'changeModeratorsAndSpeakers') {
      this.eventUserCollection.doc(this.user.id).update({state:'invited'});
    }
    // Removed ready flag when not a speaker anymore.
    else if (this.eventUser.role == null && this.eventUser.state == 'ready' && event == 'changeModeratorsAndSpeakers') {
      this.eventUserCollection.doc(this.user.id).update({state:null});
    }
    // Delete event user when leaving event.
    else if (!this.eventUser.role && event == 'leaveEvent') {
      deleteEventUser(this.event, this.user.id);
    }
    // Removes speaker when event turns complete.
    else if (event == 'eventStateChanged' && this.eventUser.role == 'speaker' && params.state == 'complete') {
      this.eventUserCollection.doc(this.user.id).update({role:null});
    }
  }
}

// ----------------------------------------------------------------------------
class VideoQnAGuestBehavior extends EventUserBehavior {
  async init() {
    log.log("VideoQnAGuestBehavior.init");
    if (!this.eventUser) {
      let data = {
        page:"manageQuestions", 
        shortBio:this.user.shortBio, 
        icon:this.user.picture0, 
        name:this.user.name, 
        role:this.event.userRoles[this.user.id]
      };
      removeNull(data);
      log.log("create eventUser:", data);
      this.eventUserCollection.doc(this.user.id).set(data, {merge:true});
      this.setPage("recordQuestion");
    }
  }
  async on(event, params) {
    log.log("videoqna bhv on", event, params, this.eventUser);
    if (event == 'questionRecordingComplete') {
      this.setPage(null);
    } else if (event == 'questionRecordNew') {
      this.setPage("recordQuestion");
    }
  }
}
