<template>
<div>
    <div>
      <button class="btn btn-secondary mb-3" @click="close()">Back</button>&nbsp;
      <button class="btn btn-secondary mb-3" v-if="$parent.debugMuted" @click="submitAndClose()">Debug Validate</button>
    </div>
    <div ref="survey">
      <h2 v-if="event.workflowOptions.showMatches">Complete the survey to see your matches</h2>
      <h2 v-else>Please, complete the survey</h2>
      <iframe ref="surveyIframe" :src="surveyUrl" style='position: absolute; left:0px; height: 1000px; width: calc(100% - 4pt); border:none'/>
    </div>
</div>
</template>

<script>
import { getLog } from "../services/log";
import { db } from "../services/db";
let log = getLog("endsurvey");

export default {
  props: {
    event: Object,
    user: Object,
  },
  computed: {
    surveyUrl() { return this.event.postSurveyLink + this.user.name; }
  },
  mounted() {
    log.log("mounted");
    let that = this;
    that.load = 0;
    this.$refs.survey.scrollIntoView(true);
    this.$refs.surveyIframe.onload = function() {
      log.log(`loaded ${that.load}`);
      /*Execute on every reload on iFrame*/
      that.load++;
      if(that.load > 1){
        /*Second reload is a submit*/
        that.submitAndClose();
      }
    }
    if (this.$debug.autoAccept)
      setTimeout(() => this.submitAndClose(), 1000);
  },
  methods: {
    close() {
      this.$parent.eventUserBehavior.on("surveyComplete");
    },
    submitAndClose() {
      log.log("submit and closing page");
      db.collection(`LiveEvents/${this.event.id}/users`).doc(this.user.id).update({ surveyComplete:true });
      this.$parent.eventUserBehavior.on("surveyComplete");
    }
  }
}
</script>