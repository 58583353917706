<template>
<div>
  <div v-if="!event.state">
    <div v-if="event.nextDate">
    Next Office Hour: {{ event.nextDate.toDate() | formatDate("dddd MMMM Do [at] h:mma z", "America/Los_Angeles") }}
    </div>
    <div v-else>
    The meeting hasn't started yet.
    </div>
  </div>
  <div v-else>
    The meeting is in progress.
  </div>
  <div v-if="eventUser && event && event.state === 'running' && event.queueIndex !== undefined">
    The host is currently in conversation with #{{ event.queueIndex + 1 }}.
  </div>
  <div class="mt-2 mt-md-5">
    How much time do you need?
    <b-form-input v-model="estimate"/>
  </div>
  <div class="mt-2 mt-md-5">
  What do you want to talk about?
    <b-form-textarea
        id="textarea"
        v-model="agenda"
        placeholder="Enter something..."
        rows="3"
        max-rows="6"
      ></b-form-textarea>
  </div>
  <b-alert variant="success" class="mt-4" :show="true" v-if="eventUser && eventUser.queuePosition !== undefined">
    Your agenda is set, you're #{{ eventUser.queuePosition + 1 }} in the queue.<br/>
    <div v-if="eventUser && eventUser.time">
      Estimated time for your meeting is {{ eventUser.time }}.
    </div>
    <div v-if="!event.state">
      Please come back when the meeting starts.
    </div>
  </b-alert>
  <div class="mt-4 mt-md-5">
    <button class="btn btn-primary mr-4" @click="save" v-if="eventUser.queuePosition === undefined">Register Spot</button>
    <button class="btn btn-primary mr-4" @click="save" v-else>Save</button>
    <button class="btn btn-outline-danger mr-4" @click="leave" v-if="eventUser.queuePosition !== undefined">Leave Queue</button>
    <b-alert :show="savedDisplay" variant="light" fade class="p-0 d-inline">
      Agenda saved... 
    </b-alert>
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("agenda");
import { db, fieldDelete } from "@/services/db";
import { addUserToEventQueue } from "@/services/user";
import { sendEmailToHost } from "@/services/notifications";

export default {
  props: {
    user: Object,
    event: Object,
    eventUser: Object,
  },
  data() {
    return {
      estimate: "",
      agenda: "",

      savedDisplay: 0,
    }
  },
  watch: {
    eventUser() {
      this.load();
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      if (this.eventUser) {
        this.estimate = this.eventUser.estimate || "";
        this.agenda = this.eventUser.agenda || "";
      }
    },
    async save() {
      let data = {
        estimate: this.estimate,
        agenda: this.agenda,
        queuePosition: this.eventUser.queuePosition
      };
      if (data.queuePosition === undefined) {
        data.queuePosition = await addUserToEventQueue(this.event);
        if (this.$app.sendEmailNotifications) {
          sendEmailToHost(this.user.name, this.event, data);
        }
      }
      await this.eventUserSetMerge(data);
      this.savedDisplay = true;
      setTimeout(() => { this.savedDisplay = false }, 1000);
    },
    async leave() {
      let data = {
        estimate: this.estimate,
        agenda: this.agenda,
        queuePosition: fieldDelete(),
      };
      await this.eventUserSetMerge(data);
      this.savedDisplay = true;
      setTimeout(() => { this.savedDisplay = false }, 1000);
    },
    async eventUserSetMerge(data) {
      log.log("eventUserSetMerge", data);
      await db.collection(`LiveEvents/${this.event.id}/users`).doc(this.user.id).set(data, {merge:true});
    },
  }
}
</script>

<style>


</style>