<template>
<div>
  {{ params.message }}
</div>
</template>

<script>
export default {
  props: {
    event: Object,
    params: Object,
  }
}
</script>

<style>

</style>