import { render, staticRenderFns } from "./stage.vue?vue&type=template&id=7f99b0c6&scoped=true&"
import script from "./stage.vue?vue&type=script&lang=js&"
export * from "./stage.vue?vue&type=script&lang=js&"
import style0 from "./stage.vue?vue&type=style&index=0&id=7f99b0c6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f99b0c6",
  null
  
)

export default component.exports