<template>
<div class="container">
  <h1>Matches</h1>
  <div v-if="!matches || !matches.length">
    <div v-if="eventId">
      No matches at tonight's event.
    </div>
    <div v-else>
      No matches.
    </div>
  </div>
  <div v-else>
    <div v-for="u in matches" v-bind:key="u.id">
      <div class="row">
        <div class="col">
          <h2>{{ u.name }}, {{ u.age }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col small">Matched on {{ u.date.toDate() | formatDate('MMM Do YYYY') }}</div>
      </div>
      <div class="row">
        <div class="col">
          <img class="imglike" :src="u.picture0"/>
        </div>
        <div class="col">
          <img class="imglike" :src="u.picture1"/>
        </div>
      </div>
      <div class="row">
        <div class="col">
          Bio: {{ u.bio }}<br/>
          Location: {{ u.location }}<br/>
          Contact: {{ u.contactType }} : {{ u.contact }}<br/>
        </div>
      </div>
      <hr/>
    </div>
  </div>
</div>
</template>

<script>
import { db } from "@/services/db";

export default {
  props: {
    eventId: String,
  },
  data() {
    return {
      matches: null,
    }
  },
  mounted() {
    let user = this.$parent.user;
    if (user) {
      var col = db.collection(`LiveUsers/${user.id}/matches`);
      if (this.eventId)
        col = col.where("eventId", "==", this.eventId);
      this.$bind("matches", col);
    }
  },
  methods: {

  }
}
</script>


<style scoped>

.imglike {
  width: 100%;
  overflow: hidden;
  border: solid 1px #000;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #000;
  object-fit: contain;
}

</style>