<template>
  <!-- User List -->
  <div class="user-list">
    <!-- Real users -->
    <template v-for="(user, index) in users">
    <div class="d-flex mb-2" v-if="!user.invisible || uiConfig.showInvisible" v-bind:key="'ul_' + index">
      <div class="mic-circle mr-3">
        <p v-if="!user.local && user.invisible">?</p>
        <font-awesome-icon icon="ghost" :style="{ color: 'white' }" size="lg" v-else-if="user.invisible"/>
        <font-awesome-icon icon="microphone-slash" :style="{ color: 'white' }" size="lg" v-else-if="user.muted"/>
        <font-awesome-icon icon="microphone" :style="{ color: 'white' }" size="lg" v-else-if="user.stream"/>
        <p v-else>?</p>
      </div>
      <div class="mr-3" v-if="user.name && (user.local || !user.invisible)">{{ user.name }}</div>
      <div class="mr-3" v-else>Waiting...</div>
      <audioviz :stream="user.stream"/>
      <font-awesome-icon icon="video" :style="{ color: 'lightgray' }" size="lg" v-if="uiConfig.showStream && user.stream"/>
      <font-awesome-icon icon="desktop" :style="{ color: 'lightgray' }" size="lg" v-if="uiConfig.showStream && user.screenStream"/>
      <div v-else-if="uiConfig && uiConfig.showReconnect">
        <button class="btn btn-warning btn-sm" @click="forceReconnect(index)">
          Force Reconnect
        </button>
      </div>
    </div>
    </template>
    <!-- Extra bot user -->
    <div class="d-flex mb-2" v-if="botName">
      <div class="mic-circle mr-3">
        <font-awesome-icon icon="microphone" :style="{ color: 'white' }" size="lg"/>
      </div>
      <div class="mr-3">{{ botName }}</div>
    </div>
  </div>
</template>

<script>
import audioviz from "../components/audioviz.vue";
import { getLog } from "../services/log";
let log = getLog('userlist');

export default {
  components: {
    audioviz,
  },
  props: {
    uiConfig: {
      type: Object,
      default: () => { return {}; },
    },
    users: Array,
  },
  data() {
    return {
      botName: null,
    }
  },
  methods: {
    updateUser(index, user) {
      this.$set(this.user, index, user);
    },
    addBotForDuration(name, duration) {
      log.log(`Message ${name} for ${duration} seconds`);
      this.botName = name;
      setTimeout(() => {
        this.botName = null;
      }, duration * 1000);
    },
    forceReconnect(index) {
      this.$emit('force-reconnect', index);
    },
  }
}
</script>

<style scoped>

.mic-circle {
  display: inline-block;
  height: 50px;
  width: 50px;
  background-color: rgb(248, 130, 130);
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  color: white;
  font-weight: bold;
  padding-top: 14px;
}

</style>