<template>
<div>
  <!-- Room -->
  <div v-if="roomId">
    <div v-if="!config.autoJoin">
      Room: {{ roomName }}
    </div>
    <div v-if="!ready">
      Press ready to join the room.
      <preview-gum/>
      <button class="btn btn-primary" @click="ready = true">Ready</button>
    </div>
    <room v-else
      ref="room"
      :roomId="roomId"
      :user="eventUser"
      :userOptions="{}"
      :config={roomCollection:config.roomCollection}
      @roomLeft="onLeaveCall"/> 
  </div>
  <!-- Select and Join -->
  <div class="form-inline sub-mr-2 sub-mt-2" v-else>
    <preview-gum/>
    <div>Select a room:</div>
    <b-form-select class="mr-2" :options="rooms" value-field="id" text-field="name" v-model="roomSelect"/>
    <button class="btn btn-primary" @click="selectRoom">Join</button>
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("multiroom", true);
import { db } from '@/services/db';
import Room from '@/components/room.vue';
import PreviewGum from '@/components/previewGum.vue';
import { bindMapFromArray } from '../services/utils';

export default {
  components: { 
    Room,
    PreviewGum,
  },
  props: {
    eventUser: Object,
    config: {
      type: Object,
      default: () => { return {}; },
    }
  },
  data() {
    return {
      ready: false,
      rooms: [
        { text: "Room 01", value: "room-01" },
        { text: "Room 02", value: "room-02" },
        { text: "Room 03", value: "room-03" },
        { text: "Room 04", value: "room-04" },
      ],
      roomsMap: {},
      roomSelect: null,
      roomName: null,
      roomId: null,
    };
  },
  mounted() {
    log.log("mounted");
    this.init();
  },
  methods: {
    async init() {
      bindMapFromArray(this, "roomsMap", "rooms");
      await this.$bind("rooms", db.collection(this.config.roomCollection || "LiveRooms"));
      if (this.rooms?.length > 0) {
        this.roomSelect = this.rooms[0].id;
        if (this.config.autoJoin)
          this.selectRoom(false);
      }
    },
    selectRoom(ready) {
      this.roomId = this.roomSelect;
      this.roomName = this.roomsMap[this.roomSelect].name;
      this.ready = ready;
    },
    onLeaveCall() {
      this.roomId = null;
    }
  }
}
</script>

<style>

</style>