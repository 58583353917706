import { db, serverTimestamp } from './db';
import { loadObj } from './dbutils';
import { removeNull } from './utils';
import { chatSend } from "./chat";
import { getLog } from "./log";
let log = getLog('room');

export async function createRoomWithData(data) {
  data.startTime = serverTimestamp();
  removeNull(data);
  log.log("create room=", data);
  let room = await db.collection("LiveRooms").add(data);
  log.log("room.id=", room.id);
  return room;
}

export async function sendRoomLink(roomId, users, message) {
  let roomLink = "room://" + roomId;
  for (let u of users) {
    chatSend(u, "status", message || "Join room", roomLink);
  }
}

export async function loadRoom(roomId) {
  return await loadObj(db.collection("LiveRooms").doc(roomId));
}
