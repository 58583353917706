<template>
<div class="grid d-flex flex-wrap align-items-top justify-content-between align-items-start">
  <div v-for="eu in eventUsers" :key="eu.id" 
    class="d-flex flex-column p-1 justify-content-center align-items-center"
    :class="{ selected: config.showSelect && eu == selected }" @click="select(eu)">
    <div class="inner-container">
      <img :src="eu.icon" class="icon" :class="{ me: eventUser.id == eu.id }"/>
      <div class="state-overlay" v-if="config.showState && eu.state">
        {{ eu.state == 'ready' ? '✅' : '🕐' }}
      </div>
      <div class="icon-overlay"  v-if="eu.reaction">
        {{ eu.reaction }}
      </div>
      <div class="binding-overlay" v-if="config.showBinding && eu.binding">
        {{ eu.binding }}
      </div>
    </div>
    <div class="label text-center">{{ eu.name }}</div>
    <div style="height:1.3em">
      <div class="label-badge badge badge-success" v-if="eu.role">{{ eu.role }}</div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    eventUser: Object,
    eventUsers: Array,
    config: {
      type: Object,
      default: () => { return {}; }
    }
  },
  data() {
    return {
      selected: null,
    }
  },
  methods: {
    select(item) {
      if (this.selected == item)
        this.selected = null;
      else
        this.selected = item;
      this.$emit('selected-user', this.selected);
    }
  }
}
</script>

<style scoped>

.grid::after {
  content: "";
  flex: auto;
}

.icon {
  width: 80px;
  height: 80px;
  object-fit: cover; 
  border: solid 0.5px #AAA;
  border-radius: 40px;
}

.me {
  border: solid 2px #AAF;
}

.label {
  margin-top: 2pt;
  max-width: 80px;
  height: 1.5em;
  overflow: hidden;
}

.label-badge {
  margin-top: 2pt;
  max-width: 80px;
  overflow: hidden;
}

.selected {
  background-color: rgb(255, 249, 192);
  border-radius: 10px;
}

.inner-container {
  display: inline-block;
  position: relative;
}

.state-overlay {
  position: absolute;
  left: 50px;
  top: 0px;
  margin: 0px;
  padding: 0px 0px;
  font-size: 20px;
  z-index: 3;
}

.binding-overlay {
  position: absolute;
  left: 0px;
  bottom: 0px;
  margin: 0px;
  padding: 0px 5px;
  font-size: 20px;
  z-index: 3;
  background-color: rgb(46, 110, 136);
  border-radius: 3px;
  color: white;
}

.icon-overlay {
  position: absolute;
  left: 50px;
  top: 45px;
  margin: 0px;
  padding: 0px 0px;
  font-size: 30px;
  z-index: 3;
}


</style>
