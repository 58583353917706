<template>
<div>
  <!-- receiver -->
  <div>
    <div class="text-center" v-if="!receiverStream">
      <div>Waiting for connection...</div>
      <progress/>
    </div>
    <video v-else :srcObject.prop="receiverStream" class="w-100" autoplay playsinline muted/>
    <broadcast :channel="event.id" @stream-change="(s) => { receiverStream = s;}"/>
  </div>
  <!-- Room -->
  <room 
    ref="room"
    :roomId="event.roomId"
    :user="eventUser"
    :userOptions="{}"
    @roomLeft="onLeaveCall"/> 
  <!-- Script -->
</div>
</template>

<script>
import Room from '@/components/room.vue';
import { updateEventUser } from "@/services/event-manager.js"

export default {
  components: {
    Room,
    Broadcast: () => import('@/components/agoraRTC.vue'),
  },
  props: {
    event: Object,
    eventUser: Object,
  },
  data() {
    return {
      receiverStream: null,
    };
  },
  mounted() {
  },
  methods: {
    onLeaveCall() {
      updateEventUser(this.event.id, this.eventUser.id, {role:null});
    }
  }
}
</script>

<style>

</style>