<template>
<div :class="!$debug.isOn ? 'render-invisible' : ''">
  <div v-for="(u, i) in users" v-bind:key="i">
    <debug-obj v-if="$debug.isOn" :label="u.name" :objData="u"/>
    <audio v-if="!(u.local || volumeMuted)" :srcObject.prop="u.stream" autoplay playsinline controls/>
  </div>
</div>
</template>

<script>
import debugObj from './debugObj.vue'
export default {
  components: { debugObj },
  props: {
    users: Array,
    volumeMuted: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style scoped>

</style>