<template>
<div>
  <h3>Hi!</h3>
  We have some bad news... today's event has already reached capacity :(<br/>
  <br/>
  Thank you so much for your patience as we continue to grow!<br/>
  <br/>
  ❤️<br/>
  The {{ $app.productName }} Team<br/>
  <a :href="`mailto:${$app.contactEmail}`">{{ $app.contactEmail }}</a>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>