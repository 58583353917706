<template>
<div>
  <!-- Stop state banner -->
  <div class="sticky-top" v-if="false">
    <div class="text-center alert-warning" 
      v-if="event.state && event.state != 'complete' && eventUser.role != null"
      :class="{
        'alert-warning':event.state == 'starting',
        'alert-danger':event.state == 'running',
      }" style="height:42px;font-size:30px">
      <span v-if="event.state == 'running'">
        ON AIR
      </span>
      <span v-else>
        OFF AIR
      </span>
    </div>
    <div class="alert-info form-inline sub-mt-2 sub-mr-2 pb-2" v-if="$debug.isOn">
      <button class="btn btn-sm btn-primary" @click="toast('Test message', 'Test title', 'info')">Toast</button>
    </div>
  </div>
  <!-- Moderator and Speakers -->
  <div v-if="event.roomId && eventUser.role" class="mb-2">
    <div v-if="$debug.isOn" class="alert-info mt-1">
      <debug-obj label="stage role" :objData="eventUser.role"/>
      <button class="btn btn-primary btn-sm" @click="turnOff = !turnOff">Turn On/Off</button>
    </div>
    <div v-if="turnOff"/>
    <!-- Moderator -->
    <div v-else-if="eventUser.role == 'moderator'">
      <div class="container" v-if="!event.state">
        This is your {{ $app.eventName }}! Check your setup, you're about to go live.
        <preview-gum class="mt-3 shadow-lg p-2 mb-5 bg-white rounded"/>
        <button class="btn btn-primary w-100" @click="updateEvent(event, {state:'starting'})">
          Start Show!
        </button>
      </div>
      <!-- See Recordings and Publish -->
      <div class="container" v-else-if="event.state == 'complete'">
        <div class="border-bottom pb-2 mb-2">
          The event has ended.
          <button class="btn btn-primary" @click="updateEvent(event, {state:null})">Reset Event</button>
        </div>
        Please select a recording to publish:
        <recordings-list :event="event" />
      </div>
      <producer-view v-else
        :event="event" 
        :eventUser="eventUser"
        :eventUsers="eventUsersModeratorsAndSpeakers"
        :eventUsersAudience="eventUsersAudience" />
    </div>
    <!-- Speaker -->
    <div class="container" v-else-if="eventUser.role == 'speaker'">
      <div class="mt-2" v-if="!event.state || eventUser.state != 'ready'">
        You're a guest on this {{ $app.eventName }}! Check your setup, you're about to go live.
        <preview-gum class="mt-3 shadow-lg p-2 mb-5 bg-white rounded"/>
        <button class="btn btn-primary w-100" v-if="eventUser.state != 'ready'"
          @click="updateEventUser(event.id, eventUser.id, {state:'ready'})">
          Ready!
        </button>
        <div v-if="!event.state && eventUser.state == 'ready'">
          You're set!<br/>
          The {{ $app.eventName }} has not started yet, this page will update when it starts.<br/>
          In the meantime, you can invite people to join the audience.
        </div>
      </div>
      <speaker-view v-else
        :event="event"
        :eventUser="eventUser" />
    </div>
    <div v-else>
      Unsupported role in stage event.
    </div>
  </div>
  <!-- Live Stream from YT or HLS or Broadcast -->
  <div v-else-if="event.state == 'running'">
    <div v-if="$debug.isOn">
      <debug-obj label="manifestURL" :objData="manifestURL"/>
      <debug-obj label="streamURL" :objData="streamURL"/>
    </div>
    <div v-if="streamURL" class="embed-responsive embed-responsive-16by9">
      <!-- Stream player URL -->
      <iframe class="embed-responsive-item" v-if="useStreaming"
        :src="streamURL" frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" scrolling="no" allowfullscreen></iframe>
      <!-- broadcast player -->
      <div v-else class="embed-responsive-item">
        <div class="text-center" v-if="!receiverStream">
          <div>Waiting for connection...</div>
          <progress/>
        </div>
        <video v-else :srcObject.prop="receiverStream" class="w-100" autoplay playsinline/>
        <broadcast :channel="event.id" @stream-change="(s) => { receiverStream = s;}"/>
      </div>
    </div>
    <div v-else>
      <h1>Stream not available, please wait...</h1>
    </div>
  </div>
  <!-- UI Slate -->
  <div class="container" v-else>
    <div class="ui-slate">
      <span v-if="!event.state">
        The {{$app.eventName}} has not started yet
      </span>
      <span v-else-if="event.state == 'starting'">
        The {{$app.eventName}} is about to start...
      </span>
      <span v-else-if="event.state == 'paused'">
        The {{$app.eventName}} is about to resume
      </span>
      <span v-else-if="event.state == 'complete'">
        The {{$app.eventName}} is over
      </span>
    </div>
    <div class="ui-sub-slate" v-if="!event.state">
      This page will update when the {{$app.eventName}} starts.<br/>
      You can invite people to watch with you.
    </div>
  </div>
  <div class="container">
    <!-- QnA Module -->
    <div v-if="!eventUser.role && event.workflowOptions.enableQnA"
      class="border-top pt-2 mb-2">
      <div v-if="!eventUser.question">
        Ask a Question
        <b-form-textarea class="mt-2" placeholder="I was wondering..." v-model="questionInput"/>
        <div class="form-inline sub-mr-2 sub-mt-2">
          <button class="btn btn-primary" @click="postQuestion">Post my question</button>
          <button class="btn btn-primary" @click="recordQuestion">Record my question</button>
        </div>
      </div>
      <div v-else>
        Your question has been recorded. Thank you for participating!
      </div>
    </div>
    <!-- Audience : Room -->
    <multi-room v-if="event.workflowOptions.audienceMultiRoomOptions"
      class="pt-2 border-top mt-2"
      :eventUser="eventUser"
      :config="event.workflowOptions.audienceMultiRoomOptions"
    />
    <!-- Audience : List -->
    <event-user-list
      v-if="isModerator || !event.workflowOptions.hideAudienceUserList && showAudience"
      class="pt-2 border-top mt-2"
      :eventUser="eventUser"
      :eventUsers="eventUsersListeners"
      @selected-user="onSelectUser"
    />
    <!-- Controls -->
    <div :class="[eventUser.role ? 'normal-bar' : 'action-bar']">
      <div v-if="showInteractions && showReact" class="form-inline sub-mt-2 sub-mr-2 mb-2">
        <button v-for="(emoji, i) in reactEmojis" :key="i"
          class="btn btn-secondary emoji-button" @click="reactSend(emoji)">{{ emoji }}</button>
      </div>
      <div v-if="showInvite" class="border-top mb-2">
        <div v-if="eventUser.role == 'moderator'">
          <div class="mt-2">Invite a guest speaker</div>
          <shareable 
            :content="guestURL"
            message="I'm inviting you to speak on my show" class="mt-2"/>
          <div class="mt-2">Invite audience</div>
          <shareable 
            :content="audienceURL"
            share-message="I'm inviting you to watch my show" class="mt-2"/>
        </div>
        <shareable v-else-if="eventUser.role == 'guest'"
          :content="audienceURL"
          share-message="I'm inviting you to see me speak on this show" class="mt-2"/>
        <shareable v-else
          :content="audienceURL"
          share-message="Come see this show with me" class="mt-2"/>
      </div>
      <div class="form-inline sub-mr-2 sub-mt-2">
        <button class="btn btn-primary" @click="showInvite = !showInvite">Invite</button>
        <button class="btn btn-primary" @click="showChat = !showChat" v-if="showInteractions && event.workflowOptions.enableChat">Chat</button>
        <button class="btn btn-primary" @click="showReact = !showReact" v-if="showInteractions && event.workflowOptions.enableReact">React</button>
        <button class="btn btn-outline-danger" @click="leave()" v-if="showAudience && !eventUser.role">Leave</button>
      </div>
    </div>
  </div>
  <!-- Sidebar: Profile -->
  <b-sidebar id="sidebar-2" right sidebar-class="border-left border-info" shadow 
    :visible="selectedUser != null" @change="profileSidebarChange">
      <div v-if="selectedUser" class="px-3 py-2">
        <profile-view v-if="selectedUser" :userId="selectedUser.id"/>
        <div v-if="isModerator && !selectedUser.role">
          <button class="btn btn-primary mr-2" @click="updateEventUser(event.id, selectedUser.id, {role:'speaker'});selectedUser = null;">Promote to Speaker</button>
        </div>
      </div>
  </b-sidebar>
  <!-- Sidebar: Chat -->
  <b-sidebar id="sidebar-chat" right no-slide no-enforce-focus sidebar-class="border-left border-danger" shadow 
    :visible="showChat" @change="chatSidebarChange">
      <div class="px-3 py-2">
        <b-alert variant="info" show>
          This chat is shared between <b>the moderator, the guest speakers and the audience</b>.
        </b-alert>
        <chat :channel="event.id" :author="eventUser.name" />
      </div>
  </b-sidebar>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("stage");
import { db } from "@/services/db"
import EventUserList from '../components/eventUserList.vue';
import ProfileView from './profileview.vue';
import ProducerView from './producerView.vue';
import DebugObj from '../components/debugObj.vue';
import SpeakerView from './speakerView.vue';
import { arraysEqual, getURLRoot, getHTTPSfromWS } from '../services/utils';
import { updateEvent, updateEventUser } from '@/services/event-manager';
import Chat from '@/components/chat.vue';
import Shareable from '@/components/shareable.vue';
import MultiRoom from '@/components/multiRoom.vue';

export default {
  components: {
    EventUserList,
    ProfileView,
    ProducerView,
    DebugObj,
    SpeakerView,
    Chat,
    Shareable,
    PreviewGum: () => import('@/components/previewGum.vue'),
    RecordingsList: () => import('@/components/recordingsList.vue'),
    Broadcast: () => import('@/components/agoraRTC.vue'),
    MultiRoom,
  },
  props: {
    event: Object,
    eventUser: Object,
  },
  data() {
    return {
      eventUsers: [],
      selectedUser: null,

      useStreaming: false,
      receiverStream: null,

      showInvite: false,
      turnOff: false,
      showChat: false,
      showReact: false,

      reactEmojis: ['👍', '🔥', '💯', '🎉', '👋', '👏', '❤️', '😮', '😹'],

      questionInput: null,
    }
  },
  computed: {
    manifestURL() {
      let fileName = this.event.isRecording ? 'stream.m3u8' : 'vod.m3u8';
      if (this.$app.useStreamingServerForServing)
        return `${getHTTPSfromWS(this.$app.streamingServerIp)}/${this.event.id}/${this.event.recordingId}/${fileName}`;
      else
        return `https://storage.googleapis.com/hh-streams/${this.event.id}/${this.event.recordingId}/${fileName}`;
    },
    streamURL() {
      return this.event.streamUrl ||
        (this.useStreaming 
          ? `${getURLRoot()}/tests/test-hls?url=${encodeURIComponent(this.manifestURL)}&type=${this.event.isRecording?'live':'vod'}&autoplay=1&startMuted=${this.$debug.isOn?1:0}` 
          : `${getURLRoot()}/tests/test-broadcast?channel=${this.event.id}`);
    },
    guestURL() {
      return this.$app.appURL + '/guest/join/' + this.event.id + '?speaker=true';
    },
    audienceURL() {
      return this.$app.appURL + '/guest/join/' + this.event.id;
    },
    // EventState
    eventStateRunningOrPaused() {
      return this.event.state == 'running' || this.event.state == 'paused';
    },
    // Users
    eventUsersModeratorsAndSpeakers() {
      return [...this.eventUsers.filter(eu => eu.role == 'moderator'), ...this.eventUsers.filter(eu => eu.role == 'speaker')];
    },
    eventUsersAudience() {
      return this.eventUsers.filter(eu => eu.role == null);
    },
    eventUsersListeners() {
      if (!this.eventUser.role)
        return [...this.eventUsersModeratorsAndSpeakers, ...this.eventUsersAudience];
      return this.eventUsersAudience;
    },
    isModerator() {
      return this.eventUser.role == 'moderator';
    },
    showAudience() {
      return ['starting', 'running', 'paused'].includes(this.event.state);
    },
    showInteractions() {
      if (!this.eventUser.role)
        return this.event.state == 'running';
      else
        return ['starting', 'running', 'paused'].includes(this.event.state);
    },
  },
  watch: {
    "event.workflowOptions.enableChat": { handler(value) {
      if (!value) this.showChat = false;
    }},
    "event.workflowOptions.enableReact": { handler(value) {
      if (!value) this.showReact = false;
    }},
    eventUsersModeratorsAndSpeakers(value, old) {
      let newIds = value.map((eu) => eu.id);
      let oldIds = old.map((eu) => eu.id);
      if (!arraysEqual(newIds, oldIds)) {
        log.log("changeModeratorsAndSpeakers", newIds);
        this.$parent.eventUserBehavior.on("changeModeratorsAndSpeakers", newIds);
      }
    }
  },
  mounted() {
    this.$app.router.disableContainer = true;
    log.log("mounted");
    this.$bind("eventUsers", db.collection(`LiveEvents/${this.event.id}/users`));
    this.newUserListener = this.$firestoreRefs.eventUsers.onSnapshot((snapshot) => {
      //log.log(".eventUsers.onSnapshot", snapshot.docChanges());
      if (!this.isModerator || snapshot.docChanges().length > 5)
        return;
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          if (change.doc.id == this.eventUser.id)
            return;
          let user = change.doc.data();
          this.toast(`${user.name} joined the ${this.$app.eventName}`);
        }
      });
    });
  },
  beforeDestroy() {
    this.$app.router.disableContainer = false;
    this.newUserListener();
  },
  methods: {
    updateEvent,
    updateEventUser,
    onSelectUser(eventUser) {
      log.log("onSelectUser", eventUser);
      this.selectedUser = eventUser;
    },
    profileSidebarChange(visible) {
      log.log("profileSidebarChange", visible);
      this.$app.router.showRightSideBar = visible || this.showChat;
      if (visible)
        this.showChat = false;
      else
        this.selectedUser = null;
    },
    chatSidebarChange(visible) {
      log.log("chatSidebarChange", visible);
      this.$app.router.showRightSideBar = visible || (this.selectedUser != null);
      this.showChat = visible;
    },
    async reactSend(e) {
      if (this.reactTimer)
        clearTimeout(this.reactTimer);
      await updateEventUser(this.event.id, this.eventUser.id, {reaction:e});
      this.reactTimer = setTimeout(async () => {
        await updateEventUser(this.event.id, this.eventUser.id, {reaction:null});
      }, 5000);      
    },
    postQuestion() {
      updateEventUser(this.event.id, this.eventUser.id, {question:this.questionInput});
    },
    recordQuestion() {
      this.$parent.eventUserBehavior.page = 'recordQuestion';
      updateEventUser(this.event.id, this.eventUser.id, {question:this.questionInput});
    },
    leave() {
      this.$parent.eventUserBehavior.on('leaveEvent');
    },
    toast(msg, title = null, variant = null) {
      this.$bvToast.toast(msg, {
        title,
        variant: variant,
        solid: true
      })
    }
  }
}
</script>

<style scoped>

.emoji-button {
  padding-top: 12px;
  font-size: 30px;
  line-height: 1;
}

.normal-bar {
  padding-bottom: 20px;
}

.action-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1030;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 25px;
  background-color: whitesmoke;
}

.ui-slate {
  text-align: center;
  padding: 10%;
  font-size: 5vw;
  width: 100%;
}

.ui-sub-slate {
  text-align: center;
  width: 100%;
}

</style>