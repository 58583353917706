<template>
<div>
  <div v-for="r in recordings.slice(0,recordingsLimit)" :key="r.id" class="border-bottom mb-2 pb-2">
    Recording #{{r.index}} 
    <span v-if="r.endTime">- {{ r.endTime.toDate() | formatDate }}</span>
    <span v-else>- Not started or in progress</span>
    <div v-if="$debug.isOn" class="alert-info">{{r.id}}</div>
    <player v-if="r.endTime" class="mt-2" style="width:50%" :url="getRecordingURL(event.id, r.id)"/>
  </div>
  <button class="btn btn-primary" @click="recordingsLimit += 3">Show More</button>

</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("recording-list");
import { db } from '@/services/db';
import { getRecordingURL } from "@/services/recordings";

export default {
  components: {
    Player: () => import('@/views/tests/TestHLS.vue'),
  },
  props: {
    event: Object,
  },
  data() {
    return {
      recordingsCollection: null,
      recordings: [],
      recordingsLimit: 3,
    }
  },
  mounted() {
    this.initRecordings();
  },
  methods: {
    async initRecordings() {
      this.recordingsCollection = db.collection(`LiveEvents/${this.event.id}/recordings`);
      await this.$bind("recordings", this.recordingsCollection.orderBy("index", "desc"));
      log.log('recordings=', this.recordings);
    },
    getRecordingURL,
  }
}
</script>

<style>

</style>