<template>
<div class="inner-container" v-if="!u.invisible && u.screenStream">
  <div class="video-overlay tl">
    <font-awesome-icon icon="desktop" :style="{ color: 'white' }" size="lg"/>
    {{ u.name ? u.name : "Waiting..." }}'s screen
  </div>
  <div class="video-overlay bl" v-if="u.note && config.showNote">
    {{ u.note }}
  </div>  
  <div class="video-overlay br cursor-pointer" v-if="config.canExpand || config.canReset" >
    <font-awesome-icon class="m-1" icon="expand" :style="{ color: 'white' }" size="lg" v-if="config.canExpand" @click="$emit('expand')"/>
    <font-awesome-icon class="m-1" icon="compress" :style="{ color: 'white' }" size="lg" v-if="config.canReset" @click="$emit('reset')"/>
  </div>  
  <video :srcObject.prop="u.screenStream" autoplay playsinline />
</div>  
</template>

<script>
export default {
  props: {
    u: null,
    config: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style lang="scss" scoped>

@import "./video.scss";

</style>
