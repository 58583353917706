<template>
<div class="vw-100 mb-2">
  <div class="alert-info" v-if="$debug.isDeveloperOn || $debug.isOn">
    <debugObj label="Host Room" :objPath="'LiveRooms/' + this.event.roomId"/>
  </div>
  <div class="form-inline sub-mr-2">
    <label>Meeting:</label>
    <div>{{this.event.state || "Not Started"}}</div>
    <button class="btn btn-primary" @click="startEvent">Start</button>
    <button class="btn btn-primary" @click="resetEvent">Reset</button>
  </div>
  <div class="form-inline sub-mt-2 sub-mr-2">
    <label>NextDate:</label>
    <b-form-datepicker id="dp" v-model="date" reset-button></b-form-datepicker>
    <b-form-timepicker id="stp" v-model="startTime" v-if="date"/>
    <button class="btn btn-primary" @click="saveDate">Save</button>
  </div>
  <div class="mt-2" v-if="orderedEventUsers">
    <div v-for="(ue, index) in orderedEventUsers" 
      v-bind:key="'ue' + index"
      class="form-inline sub-mt-2 sub-mr-2"
      :class="[ue.queuePosition === event.queueIndex ? 'selected' : '']">
      <div class="arrow" @click="moveEventUserQueuePosition(index, -1)">🔼</div>
      <div class="arrow" @click="moveEventUserQueuePosition(index, 1)">🔽</div>
      <div>[{{ ue.queuePosition }}]</div>
      <img class="icon rounded" :src="ue.image"/>
      <div>{{ ue.name }}</div>
      <template v-if="ue.queuePosition !== undefined">
        <button class="btn btn-sm btn-primary" @click="selectUser(ue)">Talk</button>
        <div>Est: {{ ue.estimate }}</div>
        <b-form-timepicker minutes-step="5" size="sm" :value="ue.time" v-model="ue.time" @hidden="setTime(ue.id, ue.time)"/>
        <div>Agenda: {{ ue.agenda }}</div>
        <button class="btn btn-sm btn-outline-warning" @click="deleteUser(ue)">Remove</button>
      </template>
      <div v-else>
        Not reserved spot
      </div>
      <div class="alert-info" v-if="$debug.isDeveloperOn || $debug.isOn">
      {{ ue.id }} : {{ ue }} 
      </div>
    </div>
    <div class="form-inline mt-2">
      <button class="btn btn-primary mr-2" @click="compact">Compact</button>
      <button class="btn btn-primary mr-2" @click="clear">Clear</button>
      <button class="btn btn-primary mr-2" @click="showInvite = !showInvite">Invite</button>
    </div>
  </div>
  <!-- Invite -->
  <div class="my-2" v-if="showInvite">
    <div class="form-inline sub-mt-2 sub-mr-2">
      <label>Invite Person:</label>
      <b-input class="col" v-model="name" placeholder="name"/>
      <b-input class="col" v-model="email" placeholder="email"/>
    </div>
    <div class="form-inline sub-mt-2 sub-mr-2">
      <label>Agenda:</label>
      <b-input v-model="agenda" class="col"/>
    </div>
    <button class="btn btn-primary mt-2" @click="sendInvite">Send Invite</button>
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog('queue-editor');
import moment from "moment";
import { db, timestampFromDate } from '@/services/db';
import { moveEventUserQueuePosition, sendEventUserIntoRoom, clearRoom, compactQueue, updateEvent, resetEvent, updateEventUser } from '@/services/event-manager';
import { removeUserFromEvent } from '@/services/user';
import { collectionFromIds } from '@/services/dbutils';
import debugObj from "@/components/debugObj";
import { arraysEqual, bindMapFromArray } from '@/services/utils';

export default {
  components: {
    debugObj,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      date: null,
      startTime: null,

      userIds: [],
      users: [],
      usersMap: {},
      eventUsers: [],
      orderedEventUsers: [],

      showInvite: false,
      name: "",
      email: "",
      agenda: "",
      editedTime: "",
    };
  },
  mounted() {
    if (this.event.nextDate)
      this.loadDate();
    this.initQueue();
  },
  methods: {
    startEvent() {
      updateEvent(this.event, {state:'running'});
    },
    resetEvent() {
      resetEvent(this.event, this.eventUsers);
    },
    loadDate() {
      let ndate = this.event.nextDate.toDate();
      this.date = moment(ndate.toISOString()).format("YYYY-MM-DD");
      this.startTime = moment(ndate.toISOString()).format("HH:mm");
      this.editedTime = this.startTime;
    },
    saveDate() {
      let dateStr = this.date + "T" + this.startTime;
      log.log("saveDate", dateStr);
      let nextDate = timestampFromDate(new Date(Date.parse(dateStr)));
      db.collection("LiveEvents").doc(this.event.id).update({nextDate});
    },
    initQueue() {
      this.$bind("eventUsers", db.collection(`LiveEvents/${this.event.id}/users`));
      this.$watch("eventUsers", () => { 
        this.updateOrderedEventUsers() 
      });
      this.$watch("eventUsers", async (eventUsers) => {
        let userIds = eventUsers.map(eu => eu.id);
        if (!arraysEqual(this.userIds, userIds)) {
          let col = await collectionFromIds(db.collection("LiveUsers"), userIds);
          log.log("userIds=", userIds, col);
          this.$bind("users", col);
          bindMapFromArray(this, "usersMap", "users");
        }
      });
    },
    updateOrderedEventUsers() {
      log.log("updateOrderedEventUsers");
      let ordered = this.eventUsers.filter((eu) => !eu.role);
      ordered.sort((a, b) => a.queuePosition - b.queuePosition );
      this.orderedEventUsers = ordered;
    },
    moveEventUserQueuePosition(index, dir) {
      moveEventUserQueuePosition(this.event, this.eventUsers, index, dir);
    },
    selectUser(eu) {
      sendEventUserIntoRoom(this.event, eu);
    },
    async deleteUser(eu) {
      if (await this.$bvModal.msgBoxConfirm(`Do you want to remove ${eu.name} from the queue?`, {centered: true}))
        removeUserFromEvent(eu.id, this.event);
    },
    clear() {
      clearRoom(this.event);
    },
    compact() {
      compactQueue(this.event, this.orderedEventUsers);
    },
    sendInvite() {
      let joinUrl = `${this.$app.appURL}/guest/join/${this.event.id}`;
      // TODO: Find the name of the Host of the event.
      let hostName = "Laurent";
      db.collection("ExtMail").add({
        to: this.email,
        from: this.$app.contactEmail,
        message: {
          subject: `You're invited to ${hostName}'s ${this.$app.productName}`,
          html: `Hi ${this.name},<br/>
            <br/>
            ${hostName} is suggesting this agenda for the meeting:<br/>
            ${this.agenda}<br/>
            <br/>
            Please join here: ${joinUrl}
          `,
        },
      })
    },
    setTime(uid, time) {
      log.log(`${uid} ${time}`);
      time = time.slice(0, 5); 
      updateEventUser(this.event.id, uid, {time});
    }
  }
}
</script>

<style scoped>

.selected {
  background-color: honeydew;
}

.arrow {
  cursor: pointer; 
  font-size:20px;
  margin-right: 5px;
}

.icon {
  width: 30px;
  height: 30px;
}

</style>
