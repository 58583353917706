import { getLog } from "./log";
let log = getLog("notifs");
import { db } from "./db";
import { appConfig, veeedConfig } from "./appconfig";
import axios from "axios";

export async function sendEmailToHost(userName, event, params) {
  log.log("sendEmailToHost");
  let hostName = "Laurent";
  let hostEmail = "laurent.mascherpa@gmail.com";
  if(hostEmail) {
    await db.collection("ExtMail").add({
      to: hostEmail,
      from: appConfig.contactEmail,
      message: {
        subject: `${userName} is joining your ${appConfig.productName}`,
        html: `Hi ${hostName},<br/>
          <br/>
          ${userName} is requesting a meeting for ${event.nextDate.toDate()}<br/>
          <br/>
          Duration estimated: ${params.estimate}<br/>
          <br/>
          Agenda for the meeting:<br/>
          ${params.agenda}<br/>
        `,
      },
    });
  } else {
    log.log("Host has no email address");
  }
}

// Send a visit email to the owner of the document with information about the visitor
export async function sendVisitEmailToUser(owner, doc, link, visit) {
  log.log("sendVisitEmailToUser");
  if(owner.email) {
    await db.collection("ExtMail").add({
      to: owner.email,
      from: veeedConfig.contactEmail,
      message: {
        subject: `[${veeedConfig.productName}] Your ${doc.type} ${doc.name} was just viewed using ${link.visitor} link`,
        html: `Hi ${owner.displayName},<br/>
          <br/>
          Your ${doc.type} <b>${doc.name}</b> was just viewed using <b>${link.visitor}</b> link<br/>
          <br/>
          <u>Details</u><br/>
          Authenticated: ${visit.authenticated ? true : false}<br/>
          Device: ${visit.device.browser}<br/>
          Location: ${visit.location?.city}, ${visit.location?.region}, ${visit.location?.country_name}<br/>
          <br/>
        `,
      },
    });
  } else {
    log.log("Owner has no email address");
  }
}

export async function sendVisitWebhookToUser(owner, doc, link, visit) {
  log.log("sendVisitWebhookToUser");
  // Send webhook to owner
  let webhookUrl = owner.settings.webhookUrl;
  if(webhookUrl) {
    let body = {
      "username": "Veeed",
      "avatar_url": "https://placekitten.com/250/250",
      "content": 
`👀 Your ${doc.type} **${doc.name}** was just viewed using **${link.visitor}** link
__Details:__
Authenticated: ${visit.authenticated ? true : false}
Device: ${visit.device.browser}
Location: ${visit.location?.city}, ${visit.location?.region}, ${visit.location?.country_name}`
    };
    await axios.post(webhookUrl, body);
  } else {
    log.log("Owner has no webhook URL");
  }
}