<template>
<div>
  <div class="form-inline sub-mr-2">
    <h1 style="display: inline;">Multi Guests</h1>
    <b-form-checkbox v-model="$debug.isOn">Debug On</b-form-checkbox>
    <b-form-checkbox v-model="$debug.autoAccept">AutoAccept On</b-form-checkbox>
  </div>
  <b-tabs content-class="mt-2">
    <b-tab :title="u.name" v-for="(u, i) in users" v-bind:key="u.id" @click="selectGuest(u)">
    <div class="container">
      <div class="sub-mr-2 mb-2">
        <button class="btn btn-warning" @click="removeUser(u.id)">Remove {{ u.name }} from Multiguest</button>
        <button class="btn btn-warning" @click="$refs.guests[i].forceUpdate()">Reload</button>
      </div>
      <guest :manager="manager" ref="guests" :propUid="u.id"/>
    </div>
    </b-tab>
  </b-tabs>
</div>
</template>

<script>
import { db } from "@/services/db";
import { getLog } from "@/services/log";
import { loadObj } from "@/services/dbutils.js";
import guest from "../guest/Guest.vue";
let log = getLog('test');

export default {
  name: 'app',
  components: {
    guest
  },
  data() {
    return {
      manager: null,
      users: [],
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.$debug.isOn = true;
      this.$debug.autoAccept = true,
      this.$app.router = {};
      await this.$bind("manager", db.collection("LiveEvents").doc("manager"));
      let eventId = this.$route.query.eventId;
      if (eventId) {
        log.log("eventId=", eventId);
        let event = await loadObj(db.collection("LiveEvents").doc(this.$route.query.eventId));
        let users = event.userIds;
        log.log("users=", users);
        this.users = users.map(id => ({id, name:id}));
        log.log("users=", this.users);
      }
      if (this.$route.query.users) {
        this.users = JSON.parse(this.$route.query.users);
        log.log("users=", this.users);
      }
    },
    selectGuest(u) {
      document.title = "MG - " + u.name;
    },
    removeUser(uid) {
      this.users = this.users.filter(u => u.id != uid);
    }
  }
}
</script>