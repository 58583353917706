<template>
<div class="align-self-left">
  <div v-if="$debug.isOn">ID: {{ userId }}</div>
  <div v-if="user">
    <img class="icon" :src="user.picture0"/>
    <div class="h2 mt-5">{{ user.name }}</div>
    <div v-if="user.bio">{{ user.bio }}</div>
  </div>
</div>
</template>

<script>
import { db } from "@/services/db"

export default {
  props: {
    userId: String,
  },
  data() {
    return {
      user: null,
    }
  },
  watch: {
    userId() {
      this.loadUser();
    }
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    loadUser() {
      this.$bind("user", db.collection("LiveUsers").doc(this.userId));
    }
  }
}
</script>

<style>
.icon {
  width: 240px;
  height: 240px;
  object-fit: cover; 
  border: solid 0.5px #AAA;
  border-radius: 120px;
}
</style>