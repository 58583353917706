<template>
<div>
  <div ref="messageList" :class="{ 'limit-overflow' : config.limitOverflow }"> 
    <div v-for="m in messages" :key="m.id">
      <!-- Bubble template -->
      <div v-if="m.archived != true && (!m.log || config.isAdmin) && !(m.author == 'status' && config.hideStatus)">
        <small class="text-muted">{{ m.author == "status" ? "" : m.author }}</small>&nbsp;
        <small class="text-muted">- {{ m.timestamp | timesince }}</small>&nbsp;
        <button class="btn badge badge-pill badge-light btn-sm" v-if="config.isAdmin" @click="archive(m.id)">x</button>
        <div 
          class="speech-bubble"
          v-bind:class="{ 'not-me': m.author != author && m.author != 'status', 'status': m.author == 'status', 'log': m.log }"
          >
          <div v-if="isPicture(m.text)">
            <img :src="m.text" class="imgchat"/>
          </div>
          <span style="word-break: break-all;" v-else>{{ m.text }}</span>
          <button 
            class="btn btn-success ml-2" 
            v-if="m.link && !m.link.startsWith('http') && !m.link.startsWith('/')" 
            @click="$emit('special-link', m.link)">Go!</button>
          <a 
            class="btn btn-primary ml-2" 
            v-else-if="m.link" :href="m.link" target="_blank">Link</a>
        </div>
        <!-- Debug -->
        <div v-if="$debug.isOn" class="alert-info">
          {{ m.id }} {{ m.link }}
        </div>
      </div>
    </div>
    <div>
    </div>
  </div>
  <div ref="editbox" class="mt-2 pt-2 border-top">
    <!-- Input -->
    <b-form-input ref="chatBox" autocomplete="off" v-model="chatText" @keyup.enter="send(chatText, chatLink)"/>
    <b-form-input placeholder="(optional) http:// or room:// or page://" v-model="chatLink" v-if="config.isAdmin"/>
    <div class="form-inline sub-mr-2 sub-mt-2">
      <button class="btn btn-primary" @click="send(chatText, chatLink)">Send</button>
      <div>or Press Enter</div>
    </div>
  </div>
</div>
</template>

<script>
import { getLog } from "../services/log"
let log = getLog('chat');
import { db, serverTimestamp } from '../services/db'
import { setTimeout } from 'timers';
export * from "../services/chat"

export default {
  props: {
    channel: {
      type: String,
      default: ''
    },
    author: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default: () => { return {}; }
    },
  },
  data() {
    return {
      chatText: "",
      chatLink: "",
      messages: [],
    };
  },
  mounted() {
    log.log('mounted chat', this.channel);
    let startDate = new Date(new Date().setDate(new Date().getDate() - 1))
    this.$bind('messages', db.collection("LiveMessages")
      .orderBy("timestamp")
      .where("timestamp", ">", startDate)
      .where("channel", "==", this.channel));
  },
  watch: {
    messages() {
      if (this.config.autoScroll) {
        setTimeout(() => {
          if (this.$refs.messageList)
            this.$refs.messageList.scrollTop = this.$refs.messageList.scrollHeight;
        }, 100);
      }
    }
  },
  methods: {
    isPicture(text) {
      return this.config.convertLinkToPicture && text.startsWith("http");
    },
    archive(mid) {
      db.collection("LiveMessages").doc(mid).update({archived:true});
    },
    async send(text, link) {
      let msg = {
        channel: this.channel, 
        timestamp: serverTimestamp(),
        author: this.author,
        text,
        link,
      };
      if (!msg.text && !msg.link)
        return;
      log.log("chat Send", msg);
      await db.collection("LiveMessages").add(msg);
      this.chatText = "";
      this.chatLink = "";
    }
  }
}
</script>

<style lang="scss" scoped>

.limit-overflow {
  max-height: 30vh;
  overflow: auto;
}

.status:after {
  display:none;
}

.status {
  background: lightgoldenrodyellow !important;
}

.log:after {
  display:none;
}

.log {
  background: lightblue !important;
}

.not-me {
  background: #eeeeee !important; 
}
.not-me:after {
  border-right-color: #eeeeee !important; 
}

.speech-bubble {
	background: #deedf0;
	border-radius: .4em;
  padding: 0.5em;
  width: 100%;
}

.imgchat {
    max-height: 500px;
    width: 100%;
    overflow: hidden;
    border: solid 1px #000;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: #000;
    object-fit: contain;
}

</style>
