<template>
<div class="bg-light rounded p-3 mb-3">
  <div>Room started {{ room.startTime | timesince  }}</div>
  <div v-if="room.timer">Time Left: {{ timeLeft }}</div>
  <div class="form-inline sub-mt-2 sub-mr-2">
    <div>Layout:</div>
    <b-form-select inline size="sm" v-model="r.layout" :options="layoutOptions"/>
    <div>Num Columns:</div>
    <b-form-input type="number" size="sm" v-model="r.layoutNumColumns"/>
  </div>
  <b-form-checkbox v-model="r.canShareScreen">Allow screen sharing</b-form-checkbox>
  <b-form-checkbox v-model="r.canTurnVideoOff">Allow to turn off video</b-form-checkbox>
  <b-form-checkbox v-model="r.startMuted">Start muted</b-form-checkbox>
  <div class="mb-2">
    Content:<br/> 
    <textarea rows="5" cols="40" v-model="r.content" placeholder="Text or JSON Object"/>
    <b-form-checkbox v-model="r.hideContent">Hide Content</b-form-checkbox>
    <b-form-checkbox v-model="r.canHideContent">Allow to Hide Content</b-form-checkbox>
  </div>
  <div class="mb-2">Video: <input type="checkbox" v-model="r.video"></div>
  <div class="mb-2">Timer: <input type="number" v-model="r.timer"/></div>
  <div class="mb-2"><button class="btn btn-primary" @click="roomConfigUpdate">Apply Changes to Room</button></div>
  <div class="mb-2"><button class="btn btn-danger mr-3" @click="closeRoom">Close&nbsp;Room (Set Timer to 0)</button></div>
</div>
</template>

<script>
import { db } from '@/services/db';
import { getLog } from "@/services/log";
import { serverEpoch } from "@/services/server-time";
import { formatTimer, removeUndefined } from "@/services/utils";
let log = getLog('room-editor');

export default {
  props: {
    room: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      r: {
        content: "",
        timer: 0,
        video: false,
        layout: null,
        canShareScreen: false,
        canTurnVideoOff: false,
        startMuted: false,
        hideContent: false,
      },

      // Computed
      timeLeft: 0,
      timeLeftHandler: null,

      // Options
      layoutOptions: [ 
        null,
        "side-by-side", 
        "vignette"
      ],
    }
  },
  watch: {
    room: {
      immediate: true,
      handler(value) {
        Object.assign(this.r, value);
        if (typeof this.r.content != 'string')
            this.r.content = JSON.stringify(this.r.content);
        delete this.r.startTime;
      }
    }
  },
  mounted() {
    this.timeLeftHandler = setInterval(() => {
      let epoch = serverEpoch();
      this.timeLeft = formatTimer(this.room.startTime.seconds, this.room.timer, epoch);
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timeLeftHandler);
  },
  methods: {
    roomConfigUpdate(timerZero) {
      let data = this.r;
      if (typeof data.content == 'string' && data.content.startsWith("{\""))
        data.content = JSON.parse(data.content);
      if (timerZero)
        data.timer = 0;
      log.log("Updating Live room", data);
      removeUndefined(data);
      db.collection("LiveRooms").doc(this.room.id).update(data);
      this.$emit("done");
    },
    closeRoom() {
      this.roomConfigUpdate(true);
    },
  }
}
</script>
