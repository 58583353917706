import { db, serverTimestamp } from "./db"
import { deleteCollection } from "./dbutils"
import { getLog } from "./log"
let log = getLog('lib-chat');

export function chatSend(uid, author, text, link) {
  return new Promise((resolve, reject) => {
    let msg = {
      channel: uid, 
      timestamp: serverTimestamp(),
      author: author
    };
    msg.text = text;
    if (link)
      msg.link = link;
    if (!msg.text && !msg.link)
      return reject(null);
    db.collection("LiveMessages").add(msg).then((m) => {
      resolve(m);
    });
  });
}

export function chatSendStatus(uid, status, log) {
  let m = { 
    channel: uid, 
    text: status,
    author: "status",
    timestamp: serverTimestamp(),
  };
  if (log)
    m.log = log;
  return db.collection("LiveMessages").add(m);
}

export function chatArchive(uid) {
  db.collection("LiveMessages").where("channel", "==", uid).get().then((qs) => {
    let batch = db.batch();
    var count = 0;
    qs.forEach((msg) => {
      if (!msg.data().archived) {
        batch.update(msg.ref, {"archived": true});
        count += 1;
      }
    });
    log.log(`archiving ${count} messages for ${uid}`);
    batch.commit();
  });
}

export function chatDelete(uid) {
  let count = deleteCollection(db.collection("LiveMessages").where("channel", "==", uid));
  log.log(`deleted ${count} messages for ${uid}`);
}
